import { Link as JoyLink } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Link,
  useActionData,
  useRouteError,
  useNavigate,
} from 'react-router-dom';

import { auth } from 'config/firebaseInit.ts';
import { IError } from 'types/index';

const Login = () => {
  const { t } = useTranslation('common');
  const error = useRouteError();
  const response = useActionData() as { data?: IError | null };
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(`/`);
    }
  }, [navigate, user]);

  React.useEffect(() => {
    if (response?.data?.severity === 'error') {
      enqueueSnackbar(t(`authMessages.${response?.data?.code}`), {
        variant: 'error',
      });
    }
  }, [response?.data, error, t]);

  return (
    <Box
      component="main"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: 400,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
        [`& .${formLabelClasses.asterisk}`]: {
          visibility: 'hidden',
        },
      }}
    >
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Typography level="h3">{t('auth.login')}</Typography>
          <Typography level="body-sm">
            {t('auth.noAccount')}{' '}
            <JoyLink href="https://www.abrico.eco/contact" level="title-sm">
              {t('auth.contactUs')}
            </JoyLink>
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={(theme) => ({
          [theme.getColorSchemeSelector('light')]: {
            color: { xs: '#FFF', md: 'text.tertiary' },
            '--Divider-lineColor': {
              xs: '#FFF',
              md: 'var(--joy-palette-divider)',
            },
          },
        })}
      ></Divider>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Form method="post" replace>
          <FormControl required>
            <FormLabel>{t('auth.email')}</FormLabel>
            <Input type="email" name="email" />
          </FormControl>

          <FormControl required>
            <FormLabel>{t('auth.password')}</FormLabel>
            <Input type="password" name="password" />
          </FormControl>

          <Stack gap={4} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* TODO: remember user login
              <Checkbox size="sm" label={t('auth.rememberMe)} name="persistent" />
            */}

              <Link to="/passwordReset" style={{ textDecoration: 'none' }}>
                <Button variant="plain" size="sm">
                  {t('auth.forgotPassword')}
                </Button>
              </Link>
            </Box>
            <Button type="submit" fullWidth>
              {t('auth.login')}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Box>
  );
};

export default Login;
