import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { MutableRefObject } from 'react';

import EntityCard from 'components/Entity/EntityCard';
import { useAppState } from 'stores/appStore.ts';
import {
  IMissingEntity,
  TEntityGroup,
  TFakeEntityGroup,
  TFakeMissingEntityGroup,
} from 'types/index';

type Props = {
  groups: (TFakeEntityGroup | TEntityGroup)[];
  missingEntities: IMissingEntity[];
  docType: string;
  docKey: string;
  sectionName: string;
  scrollOffset: number;
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
};

const DocSectionEntities = ({
  groups,
  missingEntities,
  sectionName,
  scrollOffset,
  entityGlobalAutoSelectRef,
}: Props) => {
  const { company } = useAppState();

  const mergedGroups: (
    | TFakeMissingEntityGroup
    | TFakeEntityGroup
    | TEntityGroup
  )[] = [...groups];
  if (missingEntities.length > 0) {
    mergedGroups.unshift({
      missingEntities,
      entities: [],
      fakeGroup: true,
    } as TFakeMissingEntityGroup);
  }

  return (
    <Stack
      key={sectionName}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      sx={{ width: '100%' }}
      marginBottom={'15px'}
    >
      <Typography
        level="body-sm"
        sx={{ fontWeight: '600' }}
        style={{
          color: 'var(--joy-palette-text-primary)',
        }}
      >
        {sectionName}
      </Typography>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width={'100%'}
      >
        {mergedGroups
          .filter(
            (group) =>
              company?.featureFlags.handleMissingFields ||
              group.entities.length > 0
          )
          .map((group, idx) => (
            <Stack
              key={idx}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              width={'100%'}
              sx={{
                borderLeft: !group.fakeGroup
                  ? '2px solid var(--joy-palette-text-primary)'
                  : 'unset',
                marginBottom: !group.fakeGroup ? 2 : '0',
                paddingLeft: !group.fakeGroup ? 1 : '0',
                borderRadius: 5,
              }}
            >
              {!group.fakeGroup && (
                <Typography level="body-sm">{group.label}</Typography>
              )}
              {[...group.entities, ...group.missingEntities]
                .filter(
                  (entity) =>
                    company?.featureFlags.handleMissingFields ||
                    entity.kind !== 'missing'
                )
                .map((entity) => (
                  <EntityCard
                    key={entity.id}
                    entity={entity}
                    scrollOffset={scrollOffset}
                    entityGlobalAutoSelectRef={entityGlobalAutoSelectRef}
                  />
                ))}
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default DocSectionEntities;
