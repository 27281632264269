import { useCallback, useEffect, useMemo, useState } from 'react';
import { groupBy } from 'underscore';

import { useUiState } from 'stores/uiStore.ts';
import {
  IDocType,
  IDocTypesByFileByPage,
  IEntityForDisplay,
  IMissingEntity,
  IPostProcessedEntity,
  IPreparedDocEntities,
  IPreparedDocSection,
  IPreparedEntities,
  TAnalysisResponse,
  TEntityGroup,
  TFakeEntityGroup,
  TGroupDef,
} from 'types/index';
import { docTypeOrderInOutline } from 'utils/constants.ts';
import { Dossier } from 'utils/dossier.ts';
import { reOrderDocTypes } from 'utils/reorderDocumentPages.ts';

/**
 * Function to get the extra label for all documents based on their entities.
 *
 * @param {IDocTypesByFileByPage} docTypesByFileByPage Description of the document
 * @param {IEntity[]} entities - The entities of all documents.
 * @returns {Record<string, string>} - A record mapping document keys to their extra labels.
 */
export const getDocExtraLabelByDocKey = (
  docTypesByFileByPage: IDocTypesByFileByPage,
  entities: IEntityForDisplay[]
): Record<string, string> => {
  const out: Record<string, string> = {};

  const entitiesByDocKey = groupBy(entities, (entity) => entity.docKey!);

  Object.entries(docTypesByFileByPage).forEach(([, pagesByDocKey]) => {
    // we keep track of used extra labels to add (1), (2) etc.
    const extraLabelCounter = new Map<string, number>();

    Object.entries(pagesByDocKey)
      .sort((a, b) => Math.min(...a[1]) - Math.min(...b[1]))
      .forEach(([docKey], docIndex) => {
        let docExtraLabel = ` (${docIndex + 1})`;
        for (const item of entitiesByDocKey[docKey] ?? []) {
          if (!item.transformedText) continue;
          if ((item.type as string) === 'invoice_id') {
            docExtraLabel = `${item.transformedText}`;
            break;
          } else if (item.type === 'annee_revenus') {
            docExtraLabel = item.transformedText;
            break;
          }
        }
        if (extraLabelCounter.has(docExtraLabel)) {
          extraLabelCounter.set(
            docExtraLabel,
            extraLabelCounter.get(docExtraLabel)! + 1
          );
          docExtraLabel += ` (${extraLabelCounter.get(docExtraLabel)!})`;
        } else {
          extraLabelCounter.set(docExtraLabel, 1);
        }

        out[docKey] = docExtraLabel;
      });
  });

  return out;
};

const getEntityGroups = (
  groupDef: TGroupDef,
  entityByType: Record<string, (IEntityForDisplay | IPostProcessedEntity)[]>
) => {
  // We retrieve all entities associated with the group fields
  const groupKindEntities = groupDef.fields.flatMap(
    (field) => entityByType[field] || []
  );

  // We group the entities by their groupKey
  const entitiesByGroup = groupBy(
    groupKindEntities,
    (entity) => entity.groupKey ?? 0
  );
  // We sort the different groups (they likely be in visual order after)
  const groupsSorted = [...Object.keys(entitiesByGroup)];
  // We need to parseInt because groupBy results in a Object and ints (keys) are casted to string
  groupsSorted.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

  // Finally, for each group, we return the entities
  return groupsSorted.flatMap((groupKey, idx) => {
    const entitiesInGroupByType = groupBy(
      entitiesByGroup[groupKey],
      (entity) => entity.type
    );
    return {
      fakeGroup: false,
      label: `${groupDef.label} - ${idx + 1}`,
      entities: groupDef.fields
        .filter((field) => entitiesInGroupByType[field])
        .map((field) => entitiesInGroupByType[field][0]),
      // FIXME Missing entities should be properly handled in groups too
      missingEntities: [],
    } as TEntityGroup;
  });
};

const getPreparedSectionEntities = (
  docType: IDocType,
  docKey: string,
  docEntities: (IEntityForDisplay | IPostProcessedEntity)[],
  analysis: TAnalysisResponse
): IPreparedDocSection[] => {
  const entityByType = groupBy(docEntities, (entity) => entity.type);
  const sections = analysis.sections[docType]!;

  const missingFieldsTypes = analysis.missingFields[docType]![docKey];

  return sections
    .map(({ label, fields }) => {
      const sectionFields = new Set(
        fields.flatMap((el) => (typeof el === 'string' ? [el] : el.fields))
      );

      const groups: (TFakeEntityGroup | TEntityGroup)[] = [];

      fields
        // We keep only fields that are displayable
        .filter((el) =>
          typeof el === 'string'
            ? entityByType[el]
            : el.fields.some((field) => entityByType[field])
        )
        .forEach((groupOrField) => {
          if (typeof groupOrField === 'string') {
            groups.push({
              fakeGroup: true,
              entities: [entityByType[groupOrField][0]],
              missingEntities: [],
            } as TFakeEntityGroup);
          } else {
            getEntityGroups(groupOrField, entityByType).forEach((el) =>
              groups.push(el)
            );
          }
        });

      // FIXME We should only have missing entities that are outside of groups here
      const missingEntities: IMissingEntity[] = missingFieldsTypes
        .filter((el) => sectionFields.has(el.type))
        .map((entity) => ({
          ...entity,
          docType,
          docKey,
          kind: 'missing',
        }));

      return {
        sectionName: label,
        groups,
        missingEntities: missingEntities,
      };
    })
    .filter((el) => el.groups.length > 0 || el.missingEntities.length > 0);
};
const getPreparedDocsEntities = (
  analysis: TAnalysisResponse,
  entities: (IEntityForDisplay | IPostProcessedEntity)[],
  docType: IDocType,
  isLastType: boolean
): IPreparedDocEntities[] => {
  const subEntitiesGroupedByDocKey = groupBy(
    entities,
    (entity) => entity.docKey!
  );

  const subEntitiesForDocSorted = Object.entries(
    subEntitiesGroupedByDocKey
  ).sort(([, entitiesA], [, entitiesB]) => {
    return (
      Math.min(
        ...entitiesA.map((el) => ('pageNumber' in el ? el?.pageNumber : 0))
      ) -
      Math.min(
        ...entitiesB.map((el) => ('pageNumber' in el ? el?.pageNumber : 0))
      )
    );
  });

  return subEntitiesForDocSorted.map(([docKey, entities], idx, arr) => ({
    docKey,
    docType,
    isLastDoc: isLastType && idx === arr.length - 1,
    preparedSection: getPreparedSectionEntities(
      docType,
      docKey,
      entities,
      analysis
    ),
  }));
};
/**
 * Prepare entities for rendering (correct tree & order)
 */
const prepareEntities = (dossier: Dossier): IPreparedEntities => {
  const uniqueEntities = [
    // All entities here are expected as unique at this point
    ...dossier.remappedEntities,
    ...dossier.analysis.postProcessedEntities,
  ];

  const entitiesGroupedByDocType = groupBy(
    uniqueEntities,
    (entity) => entity.docType!
  );

  const docTypesSorted = reOrderDocTypes(
    Object.keys(entitiesGroupedByDocType) as IDocType[],
    docTypeOrderInOutline
  );
  const out: IPreparedEntities = [];
  for (const docType of docTypesSorted) {
    out.push({
      docType,
      entitiesForDoc: getPreparedDocsEntities(
        dossier.analysis,
        entitiesGroupedByDocType[docType],
        docType,
        docType === docTypesSorted[docTypesSorted.length - 1]
      ),
    });
  }

  return out;
};

/**
 * Hook to properly handle keyboard navigation of entities
 */
export const useKeyBoardNavigation = (
  dossier: Dossier
): {
  preparedEntities: IPreparedEntities;
  goNext: () => void;
  goPrevious: () => void;
  setDocIsExpanded: (docKey: string, isExpanded: boolean) => void;
} => {
  // Prepare the entities so that they have the appropriate tree structure
  const preparedEntities = useMemo(() => prepareEntities(dossier), [dossier]);

  // Main navigation logic
  const preparedEntitiesFlatten: (
    | IEntityForDisplay
    | IPostProcessedEntity
    | IMissingEntity
  )[] = useMemo(
    () =>
      preparedEntities.flatMap((el) =>
        el.entitiesForDoc.flatMap((el) =>
          el.preparedSection.flatMap((el) => [
            ...el.missingEntities,
            ...el.groups.flatMap((group) => [
              ...group.missingEntities,
              ...group.entities,
            ]),
          ])
        )
      ),
    [preparedEntities]
  );

  // We track what of which documents are expanded to enable to skip entities when navigating
  const [expendedDocs, setExpendedDocs] = useState<Set<string>>(
    new Set(preparedEntitiesFlatten.map((el) => el.docKey!))
  );

  // We create a callback to be used by the components to notify that they have changed their expanded state
  const setDocIsExpanded = useCallback(
    (docKey: string, isExpanded: boolean) => {
      setExpendedDocs((prev) => {
        const next = new Set(prev);
        if (isExpanded) {
          next.add(docKey);
        } else {
          next.delete(docKey);
        }
        return next;
      });
    },
    [setExpendedDocs]
  );

  // We create a map to easily access entities by their id
  const entityById = useMemo(() => {
    return new Map(preparedEntitiesFlatten.map((el) => [el.id, el]));
  }, [preparedEntitiesFlatten]);

  // We create a map to quickly fetch the next element by id
  const nextIdById = useMemo(() => {
    return new Map(
      preparedEntitiesFlatten.map((el, idx) => [
        el.id,
        preparedEntitiesFlatten[
          idx + 1 < preparedEntitiesFlatten.length ? idx + 1 : idx
        ].id,
      ])
    );
  }, [preparedEntitiesFlatten]);

  // We create a map to quickly fetch the previous element by id
  const prevIdById = useMemo(() => {
    return new Map(
      preparedEntitiesFlatten.map((el, idx) => [
        el.id,
        preparedEntitiesFlatten[idx - 1 >= 0 ? idx - 1 : idx].id,
      ])
    );
  }, [preparedEntitiesFlatten]);

  // We then have an internal state to keep track of which element is focused
  useEffect(
    () =>
      useUiState
        .getState()
        .setFocusedEntityId(
          preparedEntitiesFlatten.length === 0
            ? ''
            : preparedEntitiesFlatten[0].id
        ),
    [preparedEntitiesFlatten]
  );

  const findNextElement = useCallback(
    (original: string, mappingToUse: Map<string, string>) => {
      let prev = original;
      let next = mappingToUse.get(prev)!;
      while (next !== prev) {
        const nextDocKey = entityById.get(next)!.docKey!;
        if (expendedDocs.has(nextDocKey)) {
          return next;
        }
        prev = next;
        next = mappingToUse.get(prev)!;
      }
      if (expendedDocs.has(entityById.get(next)!.docKey!)) {
        return next;
      } else {
        return original;
      }
    },
    [entityById, expendedDocs]
  );

  const goNext = useCallback(
    () =>
      useUiState
        .getState()
        .setFocusedEntityId(
          findNextElement(useUiState.getState().focusedEntityId, nextIdById)
        ),
    [findNextElement, nextIdById]
  );

  const goPrevious = useCallback(
    () =>
      useUiState
        .getState()
        .setFocusedEntityId(
          findNextElement(useUiState.getState().focusedEntityId, prevIdById)
        ),
    [findNextElement, prevIdById]
  );

  return {
    preparedEntities,
    goNext,
    goPrevious,
    setDocIsExpanded,
  };
};
