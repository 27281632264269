import { httpsCallable } from 'firebase/functions';
import { z } from 'zod';

import { functions } from 'config/firebaseInit';
import {
  TAnalysisResponse,
  TMetaDossierResponse,
  TSyncValue,
  ZGenerateReviewFeedbackBody,
  ZSaveReviewFeedbackBody,
} from 'types/index';

export const invokeSplitterAndExtractorWorkflow = httpsCallable(
  functions,
  'invokeWorkflow-docSplitterAndExtractor',
  { timeout: 300000 }
);

export const getAnalysis = httpsCallable<
  { dossierId: string },
  TAnalysisResponse
>(functions, 'documentAI-getAnalysis', {
  timeout: 100000,
});

export const getMetaDossierInfo = httpsCallable<
  { dossierId: string },
  TMetaDossierResponse | null
>(functions, 'documentAI-getMetaDossierInfo', {
  timeout: 100000,
});

export const reloadMetaDossier = httpsCallable<{ dossierId: string }, void>(
  functions,
  'stationHelpers-reloadMetaDossier'
);

export const updateAbricoMetaDossier = httpsCallable<{
  dossierId: string;
  changes: Record<string, TSyncValue>;
}>(functions, 'stationHelpers-updateAbricoInfo');

export const generateReviewFeedback = httpsCallable<
  z.infer<typeof ZGenerateReviewFeedbackBody>,
  { succeeded: boolean; reviewFeedbackMarkdown: string }
>(functions, 'stationHelpers-generateReviewFeedback');

export const saveReviewFeedback = httpsCallable<
  z.infer<typeof ZSaveReviewFeedbackBody>
>(functions, 'stationHelpers-saveReviewFeedback');
