import '@fontsource/inter';
import Box from '@mui/joy/Box';
import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { hotjar } from 'react-hotjar';
import { Navigate } from 'react-router-dom';

import FullScreenLoader from 'components/UI/FullScreenLoader.tsx';
import Home from 'components/UI/Home';
import { auth } from 'config/firebaseInit';
import { useAppState } from 'stores/appStore.ts';
import { isClientSide, isProductionEnv } from 'utils/helpers';

function ProtectedRoot() {
  const [user, loading] = useAuthState(auth);
  const { setUser, company } = useAppState();

  React.useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user, setUser]);

  React.useEffect(() => {
    if (isClientSide()) {
      hotjar.initialize(
        parseInt(
          isProductionEnv() ? import.meta.env.VITE_HOTJAR_ID || '0' : '0'
        ),
        6
      );
    }
  }, []);

  if (loading || (user && !company)) return <FullScreenLoader />;
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
      {user && company ? (
        <Home companyId={company?.id} />
      ) : (
        <Navigate to="/login" />
      )}
    </Box>
  );
}

export default ProtectedRoot;
