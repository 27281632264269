import { Textarea, Tooltip } from '@mui/joy';
import Autocomplete from '@mui/joy/Autocomplete';
import Avatar from '@mui/joy/Avatar';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { TWipMetaEl } from 'types/index';

function SyncEntityElNumber({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: number = parseFloat(e.target.value);
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaKey, isNaN(value) ? null : value);
    },
    [el.metaKey]
  );

  return (
    <Input
      type={'number'}
      value={(el.value as number) || ''}
      variant={'solid'}
      disabled={isInReadOnlyMode}
      onChange={onChange}
      error={el.hasError === true}
      sx={{ width: 150 }}
    ></Input>
  );
}

function SyncEntityElStringEmail({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaKey, value || null);
    },
    [el.metaKey]
  );

  return (
    <Input
      value={(el.value as string) || ''}
      variant={'solid'}
      disabled={isInReadOnlyMode}
      onChange={onChange}
      error={el.hasError === true}
      sx={{ width: 180 }}
    ></Input>
  );
}

function SyncEntityElString({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChangeTextArea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaKey, e.target.value || null);
    },
    [el.metaKey]
  );

  const handleChangeSelect = useCallback(
    // @ts-ignore
    (_, newValue: string | null) => {
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaKey, newValue || null);
    },
    [el.metaKey]
  );

  if (Array.isArray(el.stringOptions)) {
    return (
      <Autocomplete
        options={el.stringOptions}
        onChange={handleChangeSelect}
        sx={{ margin: 1 / 2, width: '180px!important' }}
        size={'md'}
        value={(el.value as string) || ''}
        disabled={isInReadOnlyMode}
        variant={'solid'}
        color={el.hasError === true ? 'danger' : 'neutral'}
      ></Autocomplete>
    );
  }

  return (
    <Textarea
      variant={'solid'}
      sx={{ margin: 1 / 2, width: 180 }}
      size={'sm'}
      value={(el.value as string) || ''}
      disabled={isInReadOnlyMode}
      onChange={onChangeTextArea}
      error={el.hasError === true}
    ></Textarea>
  );
}

function SyncEntityElDateTime({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // We try to reuse the time part of the remote value (if present)
      // At the moment we only manage the date part, as we don't have the need to handle time too
      const extraTsPart = ((el.remoteValue as string) || '').includes('T')
        ? ((el.remoteValue as string) || '').split('T')[1]
        : '00:00:00.000Z';

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(
          el.metaKey,
          e.target.value ? `${e.target.value}T${extraTsPart}` : null
        );
    },
    [el.metaKey, el.remoteValue]
  );

  return (
    <Input
      type={'date'}
      value={((el.value as string) || '').split('T')[0]}
      variant={'solid'}
      disabled={isInReadOnlyMode}
      onChange={onChange}
      error={el.hasError === true}
    ></Input>
  );
}

function SyncEntityElBoolean({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const valueByString = {
        true: true,
        false: false,
        null: null,
      } as const;

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(
          el.metaKey,
          valueByString[e.target.value as 'true' | 'false' | 'null']
        );
    },
    [el.metaKey]
  );

  return (
    <Card variant={isInReadOnlyMode ? 'soft' : 'solid'} color={'neutral'}>
      <RadioGroup
        value={el.value}
        onChange={onChange}
        orientation={'horizontal'}
        size={'sm'}
      >
        <Radio
          value="true"
          label="Oui"
          variant="outlined"
          disabled={isInReadOnlyMode}
        />
        <Radio
          value="false"
          label="Non"
          variant="outlined"
          disabled={isInReadOnlyMode}
        />
        <Radio
          value="null"
          label="?"
          variant="outlined"
          disabled={isInReadOnlyMode}
        />
      </RadioGroup>
    </Card>
  );
}

export function EditSyncElement({ el }: { el: TWipMetaEl }) {
  switch (el.type) {
    case 'string':
      return <SyncEntityElString el={el} />;
    case 'number':
      return <SyncEntityElNumber el={el} />;
    case 'date-time':
      return <SyncEntityElDateTime el={el} />;
    case 'boolean':
      return <SyncEntityElBoolean el={el} />;
    case 'email-address':
      return <SyncEntityElStringEmail el={el} />;
    default:
      return <Typography>Unsupported type</Typography>;
  }
}

export function SyncEntityEl({ entityId }: { entityId: string }) {
  const links = useInteractionsState((state) =>
    state.metaDossierInfo &&
    Array.isArray(state.metaDossierInfo.metaDossierLinkByAnnotId[entityId])
      ? state.metaDossierInfo.metaDossierLinkByAnnotId[entityId]
      : []
  );

  const elements = useInteractionsState(
    useShallow((state) =>
      links.map(
        (link) => state.metaDossierWipElByMetaKey.get(link.metaDossierKey)!
      )
    )
  );

  if (!links.length) {
    return <></>;
  }

  return (
    <>
      <Divider />
      <Stack
        // Prevent event from bubbling to EntityCard which would cause an auto-select to happen
        onKeyDown={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
          useUiState.getState().setFocusedEntityId(entityId, false);
        }}
        onMouseMove={(e) => e.stopPropagation()}
        direction={'column'}
        gap={0.5}
      >
        {elements.map((el) => (
          <Stack key={el.metaKey} flexDirection={'row'} alignItems={'center'}>
            <Tooltip title={`Abrico : ${el.label}`}>
              <Avatar
                variant="outlined"
                src="/abrico_letter.svg"
                sx={{
                  '--Avatar-size': '1.5rem',
                  mr: 0.5,
                }}
              />
            </Tooltip>
            <EditSyncElement el={el} />
          </Stack>
        ))}
      </Stack>
    </>
  );
}
