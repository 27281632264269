// eslint-disable-next-line import/named
import { User as FirebaseUser } from 'firebase/auth';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getUserCompanies } from 'queries/company.ts';
import { getCompanyTags } from 'queries/tags.ts';
import { ICompany, ITag } from 'types/index';

interface AppState {
  user: FirebaseUser | null;
  setUser: (user: FirebaseUser | null) => void;
  userCompanies: ICompany[];
  _setUserCompanies: (companies: ICompany[]) => void;
  company: ICompany | null;
  setCompany: (company: ICompany | null) => void;
  tags: ITag[];
  refreshCompanyTags: () => Promise<void>;
}

export const useAppState = create<AppState>()(
  devtools(
    (set, get) => ({
      user: null as FirebaseUser | null,
      setUser: (user: FirebaseUser | null) => {
        set({ user });
      },
      company: null as ICompany | null,
      userCompanies: [] as ICompany[],
      _setUserCompanies: (companies: ICompany[]) => {
        set({ userCompanies: companies });
      },
      setCompany: (company: ICompany | null) => {
        set({
          company,
          tags: get().company?.id === company?.id ? get().tags : [],
        });
      },
      tags: [] as ITag[],
      refreshCompanyTags: async () => {
        const company = get().company;
        if (company) {
          const tags = await getCompanyTags(company.id);
          set({ tags });
        }
      },
    }),
    {
      name: 'app-store',
    }
  )
);

useAppState.subscribe(async (state, prevState) => {
  if (state.user?.uid !== prevState.user?.uid) {
    if (state.user?.uid) {
      const companies = await getUserCompanies(state.user.uid);
      useAppState.getState()._setUserCompanies(companies);
      useAppState.getState().setCompany(companies[0]);
    }
  }

  if (state.company?.id !== prevState.company?.id) {
    await useAppState.getState().refreshCompanyTags();
  }
});
