/* eslint import/named: 0 */ // Buggy for DocumentChange, DocumentData & Unsubscribe :cry:
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentChange,
  DocumentData,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  Unsubscribe,
  where,
  writeBatch,
  limit,
  orderBy,
} from 'firebase/firestore';

import { db } from 'config/firebaseInit';
import { storeNewFileWithMetadata } from 'queries/storage';
import {
  DossierDataAtCreation,
  DossierStatus,
  IDossier,
  Order,
  TInteractionsFS,
} from 'types/index';
import { Dossier } from 'utils/dossier.ts';
import { mergeFiles, savePDFInBucket } from 'utils/file';

export const getDossierQueryKey = (dossierId: string): string =>
  `dossier-${dossierId}`;

export const getMetaDossierQueryKey = (dossierId: string): string =>
  `meta-dossier-${dossierId}`;

export type TDossiersListQueryParams = {
  companyId: string;
  tagId: string;
  archived: boolean;
  search: string;
  order: Order;
  orderBy: keyof IDossier;
  page: number;
  pageSize: number;
};

export const getDossiersList = async (
  params: TDossiersListQueryParams
): Promise<{
  canGoNextPage: boolean;
  canGoPreviousPage: boolean;
  dossiers: IDossier[];
}> => {
  const dossiersRef = collection(db, 'dossiers');

  try {
    const qParams = [
      dossiersRef,
      where('companyId', '==', params.companyId),
      where('tagId', '==', params.tagId),
      where('archived', '==', params.archived),
    ];

    if (params.search !== '') {
      qParams.push(
        where('name', '>=', params.search),
        where('name', '<=', params.search + '\uf8ff')
      );
    }

    const q = query(
      // @ts-ignore
      ...qParams,
      orderBy(params.orderBy, params.order),
      // We cannot implement proper pagination with Firestore, so we limit the number of dossiers fetched (nasty I know)
      limit(params.page * params.pageSize + 1)
    );
    const querySnapshot = await getDocs(q);

    // Map the documents to conform to the IDossier interface
    const docs = querySnapshot.docs;
    const canGoNextPage = docs.length > params.page * params.pageSize;
    const canGoPreviousPage = params.page > 1;
    return {
      dossiers: querySnapshot.docs
        // Hacky paginate
        .slice(
          (params.page - 1) * params.pageSize,
          params.page * params.pageSize
        )
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
          } as IDossier;
        }),
      canGoNextPage,
      canGoPreviousPage,
    };
  } catch (error) {
    console.error('Error fetching dossiers:', error);
    return { canGoNextPage: false, canGoPreviousPage: false, dossiers: [] };
  }
};

export const createNewDossier = async (
  dossierName: string,
  selectedFiles: FileList,
  userEmail: string,
  companyId: string,
  isUniqueDevis: boolean,
  tagId: string | null,
  code: { code: string; name: string }[]
): Promise<any> => {
  const q = query(collection(db, 'dossiers'), where('name', '==', dossierName));
  const snapshot = await getCountFromServer(q);

  // 1. We Check if the dossier name already exists
  const isDossierNameExists = snapshot.data().count > 0;
  if (isDossierNameExists) {
    const snap = await getDocs(q);
    snap.forEach((doc) => {
      // if doc.data().archived === true, we can use the same name
      if (doc.data().archived === false) {
        throw new Error('duplicate');
      }
    });
  }

  // 2. We create the merged PDF file, so that we know the size of what we are uplading
  // when we create the dossier
  const mergedPdfData = await mergeFiles(selectedFiles);

  const dataDossier = {
    name: dossierName,
    createdBy: userEmail,
    createdAt: serverTimestamp(),
    companyId,
    isUniqueDevis,
    tagId,
    archived: false,
    codesAdeme: code.map(
      (option: { code: string; name: string }) => option.code
    ),
    status: DossierStatus.NEW,
    size: mergedPdfData.byteLength,
    // When creating a new dossier we enable the auto rotation feature
    autoRotatePagesInSplitter: true,
  } as DossierDataAtCreation;

  // 3. We create the dossier in FS, so that we have a dossier ID
  const newDossierRef = await addDoc(collection(db, 'dossiers'), dataDossier);

  // 4. We upload all selected input files to the storage
  const inputFolderPath = Dossier.getInputFolderPath(
    companyId,
    newDossierRef.id
  );

  const metadata = {
    contentType: 'application/pdf',
    customMetadata: {
      uploadedBy: userEmail,
    },
  };

  const promises = [];
  for (const file of selectedFiles) {
    const uploadedFilePath = `${inputFolderPath}/${file.name}`;
    const promise = storeNewFileWithMetadata(file, uploadedFilePath, metadata);
    promises.push(promise);
  }
  await Promise.all(promises);

  // 5. Lastly, we upload the merged PDF to the storage (do not do it earlier, as there are some auto triggers)
  const mergedPdfFilePath = `${inputFolderPath}/${Dossier.getGcsFileName(newDossierRef.id)}`;
  await savePDFInBucket(mergedPdfData, mergedPdfFilePath, metadata);

  return { dossierId: newDossierRef.id };
};

export const updateDossier = async (
  dossierId: string | undefined,
  data: any,
  interactions?: TInteractionsFS
) => {
  if (!dossierId) {
    return;
  }
  const dossierRef = doc(db, 'dossiers', dossierId);
  const batch = writeBatch(db);
  batch.update(dossierRef, data);

  if (interactions) {
    batch.set(doc(db, 'dossierInteractions', dossierId), {
      ...interactions,
      dossierId,
    });
  }

  await batch.commit();
};

export const getDossierById = async (dossierId: string): Promise<IDossier> => {
  const dossierRef = doc(db, 'dossiers', dossierId);
  const dossierSnapshot = await getDoc(dossierRef);
  if (!dossierSnapshot.exists()) {
    throw new Error('Dossier not found');
  }
  return {
    id: dossierSnapshot.id,
    ...dossierSnapshot.data(),
  } as IDossier;
};

export const getFSDossierInteractions = async (
  dossierId: string
): Promise<TInteractionsFS> => {
  const ref = doc(db, 'dossierInteractions', dossierId);
  const snap = await getDoc(ref);
  // dossiersInteractions might not be initialized on first load, this is ok
  // if (!snap.exists()) {
  //   throw new Error('Dossier not found');
  // }
  return {
    entitiesStatuses: {},
    entitiesRejectionReasons: {},
    ...snap.data(),
  } as TInteractionsFS;
};

export const deleteDossier = async (dossierId: string): Promise<void> => {
  const dossierRef = doc(db, 'dossiers', dossierId);

  await deleteDoc(dossierRef);
};

export const onDossiersUpdated = (
  dossiersIds: string[],
  onUpdated: (
    changes: Array<DocumentChange<DocumentData, DocumentData>>
  ) => void
): Unsubscribe => {
  const dossiersRef = collection(db, 'dossiers');
  const q = query(dossiersRef, where(documentId(), 'in', dossiersIds));

  return onSnapshot(q, (snapshot) => {
    onUpdated(snapshot.docChanges());
  });
};
