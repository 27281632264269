export const isClientSide = () => typeof window !== 'undefined';

export const isProductionEnv = () =>
  window.location.hostname === 'app.spok.in' || 'station.abrico.eco';

export const capitalize = (text: string): string => {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
