import Switch from '@mui/joy/Switch';
import * as React from 'react';

export const switchButton = ({
  checked,
  setChecked,
}: {
  checked: boolean;
  setChecked: (value: boolean) => void;
}) => {
  return (
    <Switch
      checked={checked}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setChecked(event.target.checked)
      }
      color={checked ? 'success' : 'neutral'}
      variant={checked ? 'solid' : 'outlined'}
      slotProps={{
        endDecorator: {
          sx: {
            minWidth: 24,
          },
        },
      }}
    />
  );
};
