import {
  browserLocalPersistence,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
} from 'firebase/auth';

import { auth } from 'config/firebaseInit';
import { ICompany, IError } from 'types/index';

interface AuthProvider {
  sendLink(email: string | null): Promise<void>;
  signinWithLink: () => Promise<void>;
  signout: (callbck: () => void) => Promise<void>;
  signinWithPassword(
    email: string,
    password: string,
    callbck: (user: any) => void
  ): Promise<{ company: ICompany | null; error: IError | null } | void>;
  passwordReset: (email: string) => Promise<void>;
}

const actionCodeSettings = {
  url: 'https://station.abrico.eco/login',
  handleCodeInApp: true, // necessary for email link sign-in.
};

/**
 * This represents some generic auth provider API, like Firebase.
 */
const firebaseAuthProvider: AuthProvider = {
  async sendLink(email: string) {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
  },
  async signinWithLink() {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email: string | null = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      if (email !== null) {
        await setPersistence(auth, browserLocalPersistence)
          // @ts-ignore
          .then(() => signInWithEmailLink(auth, email, window.location.href))
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn');
            return result;
          });
      }
    }
  },
  async signinWithPassword(email, password, callbck) {
    return setPersistence(auth, browserLocalPersistence).then(() => {
      return signInWithEmailAndPassword(auth, email, password).then(
        (userCredential) => callbck(userCredential?.user)
      );
    });
  },
  async signout(callbck) {
    await signOut(auth)
      .then(() => {
        callbck();
        localStorage.clear();
      })
      .catch((err) => console.error('in signout', err));
  },
  async passwordReset(email) {
    return sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => console.log('email sent'))
      .catch((err) => {
        console.error('err in passwordReset in partners app', err);
      });
  },
};

export { firebaseAuthProvider };
