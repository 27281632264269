import { Core as WeviewerCore } from '@pdftron/webviewer';
import { Mutex } from 'async-mutex';

const initializationMutex = new Mutex();

let initialized = false;
/**
 * Returns the PDFNet object
 * We use this instead of PDFNet.runWithCleanup to avoid a blocking startup time
 */
export const getPDFNet = async (): Promise<typeof WeviewerCore.PDFNet> => {
  // @ts-ignore
  const Core: typeof WeviewerCore = window.Core;

  // We run this in a mutex to avoid multiple initializations
  await initializationMutex.runExclusive(async () => {
    if (!initialized) {
      Core.setWorkerPath('/webviewer/public/core');
      await Core.PDFNet.initialize('toto');
      initialized = true;
    }
  });

  return Core.PDFNet;
};
