import Typography from '@mui/joy/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IVerificationSignatureResult } from 'types/index';

type IProps = {
  isCertification: boolean;
  ModificationPermissionsStatus: any;
  permissionStatus: any;
  translate: any;
};

export const renderPermissionStatus = ({
  isCertification,
  ModificationPermissionsStatus,
  permissionStatus,
  translate,
}: IProps) => {
  let content;
  switch (permissionStatus) {
    case ModificationPermissionsStatus.e_invalidated_by_disallowed_changes:
      content = translate(
        'digitalSignatureVerification.permissionStatus.invalidatedByDisallowedChanges'
      );
      break;
    case ModificationPermissionsStatus.e_has_allowed_changes:
      content = translate(
        'digitalSignatureVerification.permissionStatus.hasAllowedChanges'
      );
      break;
    case ModificationPermissionsStatus.e_unmodified:
      content = `${translate(
        'digitalSignatureVerification.permissionStatus.unmodified'
      )} ${
        isCertification
          ? translate('digitalSignatureVerification.certified')
          : translate('digitalSignatureVerification.signed')
      }.`;
      break;
    case ModificationPermissionsStatus.e_permissions_verification_disabled:
      content = translate(
        'digitalSignatureVerification.permissionStatus.permissionsVerificationDisabled'
      );
      break;
    case ModificationPermissionsStatus.e_no_permissions_status:
      content = translate(
        'digitalSignatureVerification.permissionStatus.noPermissionsStatus'
      );
      break;
  }

  return <Typography level="body-sm">{content}</Typography>;
};

type IWidgetInfoProps = {
  verificationResult: IVerificationSignatureResult;
  field: { name: string };
  PDFNet: any;
};
const WidgetInfo = ({
  verificationResult,
  field,
  PDFNet,
}: IWidgetInfoProps) => {
  const { VerificationResult, VerificationOptions } = PDFNet;
  const { TimeMode } = VerificationOptions;
  const { ModificationPermissionsStatus } = VerificationResult;
  const [translate] = useTranslation('apryse');

  const {
    signed,
    signTime,
    verificationStatus,
    permissionStatus,
    disallowedChanges,
    trustVerificationResultBoolean,
    timeOfTrustVerificationEnum,
    trustVerificationTime,
    isCertification,
    contactInfo,
    location,
    reason,
    signerName,
  } = verificationResult;

  const renderVerificationStatus = ({
    isCertification,
    verificationStatus,
  }: {
    isCertification: boolean;
    verificationStatus: number;
  }) => {
    const verificationType = isCertification
      ? translate('digitalSignatureVerification.Certification')
      : translate('digitalSignatureVerification.Signature');
    return (
      <Typography level="body-sm">
        {verificationStatus
          ? translate('digitalSignatureVerification.verificationStatus.valid', {
              verificationType,
            })
          : translate(
              'digitalSignatureVerification.verificationStatus.failed',
              { verificationType }
            )}
      </Typography>
    );
  };

  const renderDisallowedChanges = () => {
    return disallowedChanges.map(
      ({ objnum, type }: { objnum: number; type: string }) => (
        <Typography level="body-sm" key={objnum}>
          {translate('digitalSignatureVerification.disallowedChange', {
            type,
            objnum,
          })}
        </Typography>
      )
    );
  };

  const renderTrustVerification = () => {
    let verificationTimeMessage;
    switch (timeOfTrustVerificationEnum) {
      case TimeMode.e_current:
        verificationTimeMessage = translate(
          'digitalSignatureVerification.trustVerification.current'
        );
        break;
      case TimeMode.e_signing:
        verificationTimeMessage = translate(
          'digitalSignatureVerification.trustVerification.signing'
        );
        break;
      case TimeMode.e_timestamp:
        verificationTimeMessage = translate(
          'digitalSignatureVerification.trustVerification.timestamp'
        );
        break;
      default:
        console.warn(
          `Unexpected pdftron::PDF::VerificationOptions::TimeMode: ${timeOfTrustVerificationEnum}`
        );
    }
    return (
      <Typography level="body-sm">
        {translate(
          trustVerificationResultBoolean
            ? 'digitalSignatureVerification.trustVerification.verifiedTrust'
            : 'digitalSignatureVerification.trustVerification.noTrustVerification'
        )}
        <p>{trustVerificationTime}</p>
        <p>{verificationTimeMessage}</p>
      </Typography>
    );
  };

  const renderSignatureDetails = () => {
    // No additional signature details to render
    if (!contactInfo && !location && !reason) {
      return null;
    }
    return (
      <div>
        <p>
          {translate(
            'digitalSignatureVerification.signatureDetails.signatureDetails'
          )}
        </p>

        <div className="body">
          <div>
            <p className="bold">
              {`${translate(
                'digitalSignatureVerification.signatureDetails.contactInformation'
              )}:`}
            </p>
            <p className="result-for-header">
              {contactInfo ||
                translate(
                  'digitalSignatureVerification.signatureDetails.noContactInformation'
                )}
            </p>
          </div>
          <div>
            <p className="bold">
              {`${translate(
                'digitalSignatureVerification.signatureDetails.location'
              )}:`}
            </p>
            <p className="result-for-header">
              {location ||
                translate(
                  'digitalSignatureVerification.signatureDetails.noLocation'
                )}
            </p>
          </div>
          <div>
            <p className="bold">
              {`${translate(
                'digitalSignatureVerification.signatureDetails.reason'
              )}:`}
            </p>
            <p className="result-for-header">
              {reason ||
                translate(
                  'digitalSignatureVerification.signatureDetails.noReason'
                )}
            </p>
          </div>
          {signTime && (
            <div>
              <p className="bold">
                {`${translate(
                  'digitalSignatureVerification.signatureDetails.signingTime'
                )}:`}
              </p>
              <p className="result-for-header">
                {signTime ||
                  translate(
                    'digitalSignatureVerification.signatureDetails.noSigningTime'
                  )}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let content = isCertification
      ? translate('digitalSignatureVerification.Certified')
      : translate('digitalSignatureVerification.Signed');
    content += ` ${translate('digitalSignatureVerification.by')} ${signerName || translate('digitalSignatureModal.unknown')}`;
    if (signTime) {
      content += ` ${translate('digitalSignatureVerification.on')} ${signTime}`;
    }
    return (
      <div>
        <p>{content}</p>
      </div>
    );
  };

  return (
    <div className="signature-widget-info">
      {signed ? (
        <React.Fragment>
          {renderTitle()}
          <div>
            <div className="header">
              {renderVerificationStatus({
                isCertification,
                verificationStatus,
              })}
              <div className="body">
                {renderPermissionStatus({
                  isCertification,
                  ModificationPermissionsStatus,
                  permissionStatus,
                  translate,
                })}
                {renderDisallowedChanges()}
                {renderTrustVerification()}
              </div>
            </div>
            <div className="header header-with-arrow">
              {renderSignatureDetails()}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="title link" tabIndex={0}>
            <p> signature icon </p>
            <p>
              {translate(
                'digitalSignatureVerification.unsignedSignatureField',
                { fieldName: field.name }
              )}
            </p>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default WidgetInfo;
