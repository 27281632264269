import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';

function SignatureBadgeIcon({ badgeIcon }: { badgeIcon: string }) {
  const [translate] = useTranslation('apryse');
  let color;
  let icon;

  switch (badgeIcon) {
    case 'digital_signature_valid':
      color = 'success';
      icon = <CheckBoxIcon />;
      break;
    case 'digital_signature_warning':
      color = 'success';
      icon = <CheckBoxIcon />;
      break;
    case 'digital_signature_error':
      color = 'error';
      icon = <ErrorIcon />;
      break;
    default:
      color = 'warning';
      icon = <CheckBoxIcon />;
  }

  return (
    // @ts-ignore
    <Typography fontSize="medium" color={color} startDecorator={icon}>
      {translate('digitalSignatureVerification.badgeIcon.' + badgeIcon)}
    </Typography>
  );
}

export default SignatureBadgeIcon;
