import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import UploadFileModal from 'components/File/UploadFileModal';
import { TrackTiming } from 'components/Utils/TrackTiming.tsx';
import { useAppState } from 'stores/appStore.ts';

const Home = (props: { companyId: string }) => {
  const location = useLocation();
  const { t } = useTranslation('common');
  const { tags } = useAppState();

  const firstTag = tags[0];

  if (location.pathname === '/') {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ width: '100%' }}
      >
        <NavLink
          to={`/company/${props.companyId}/dossiers/tag/${firstTag?.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Button
            sx={{ width: '100%' }}
            variant="outlined"
            startDecorator={<AssignmentRoundedIcon />}
          >
            {t('home.dossiers')}
          </Button>
        </NavLink>
        <Box sx={{ width: 'fit-to-content' }}>
          {' '}
          <UploadFileModal />{' '}
        </Box>
        <TrackTiming name="Home Loaded" />
      </Stack>
    );
  } else {
    return <Outlet />;
  }
};

export default Home;
