import { Link as JoyLink } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';
import { Form, Link } from 'react-router-dom';

const PasswordReset = () => {
  const { t } = useTranslation('common');
  return (
    <Box
      component="main"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: 400,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
        [`& .${formLabelClasses.asterisk}`]: {
          visibility: 'hidden',
        },
      }}
    >
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Typography level="h3">{'Reset your password'}</Typography>
          <Typography level="body-sm">
            {t('auth.noAccount')}{' '}
            <JoyLink
              href="https://www.abrico.eco/nous-contacter"
              level="title-sm"
            >
              {t('auth.contactUs')}
            </JoyLink>
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={(theme) => ({
          [theme.getColorSchemeSelector('light')]: {
            color: { xs: '#FFF', md: 'text.tertiary' },
            '--Divider-lineColor': {
              xs: '#FFF',
              md: 'var(--joy-palette-divider)',
            },
          },
        })}
      ></Divider>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Form method="post" replace>
          <FormControl required>
            <FormLabel>{t('auth.email')}</FormLabel>
            <Input type="email" name="email" />
          </FormControl>

          <Stack gap={4} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Button variant="plain" size="sm">
                  {t('auth.login')}
                </Button>
              </Link>
            </Box>
            <Button type="submit" fullWidth>
              {t('auth.resetPassword')}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Box>
  );
};

export default PasswordReset;
