import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://11d9b109902369141e79768efa72a45d@o4507464710553600.ingest.de.sentry.io/4507464716189776',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['station.abrico.eco', 'app.spok.in'],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

export const forceSaveSentryReplay = async () => {
  const replay = Sentry.getReplay();
  if (replay) {
    await replay.flush({ continueRecording: true });
  }
};
