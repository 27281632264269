import { serverTimestamp } from '@firebase/firestore';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import BlockIcon from '@mui/icons-material/Block';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Link as JoyLink } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Chip from '@mui/joy/Chip';
import CircularProgress from '@mui/joy/CircularProgress';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Switch from '@mui/joy/Switch';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import { visuallyHidden } from '@mui/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import {
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import SearchBar from 'components/UI/SearchBar';
import { TrackTiming } from 'components/Utils/TrackTiming.tsx';
import {
  checkNewName,
  LIST_DOSSIERS_QUERY_KEY,
  useDossiers,
} from 'hooks/useDossiers.ts';
import { deleteDossier, updateDossier } from 'queries/dossiers';
import { useAppState } from 'stores/appStore.ts';
import {
  DossierStatus,
  IDossier,
  TranslationT,
  WorkflowStatus,
} from 'types/index';
import { formatTimeStamp } from 'utils/formatDates.ts';
import { capitalize } from 'utils/helpers';

interface HeadCell {
  disablePadding: boolean;
  id: keyof IDossier;
  label: string;
  numeric: boolean;
}

const headCells = (t: TranslationT): readonly HeadCell[] => [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: t('dossier.fields.name'),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: t('dossier.fields.status'),
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: t('dossier.fields.createdAt'),
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: t('dossier.fields.updatedAt'),
  },
  {
    id: 'size',
    numeric: true,
    disablePadding: false,
    label: t('dossier.fields.size'),
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: false,
    label: t('dossier.fields.createdBy'),
  },
  {
    id: 'archivedAt',
    numeric: false,
    disablePadding: false,
    label: t('dossier.fields.archivedAt'),
  },
];

const formatSize = (value: number): string => {
  // format the file size in MB
  return (value / 1000000).toFixed(2) + ' MB';
};

const formatUserEmail = (value: string): string => {
  return capitalize(value.split('@')[0].split('.').join(' '));
};

function RowMenu({
  rowActions,
}: {
  rowActions: {
    label: string;
    action: () => void;
    disabled: boolean;
    color: 'danger' | 'neutral';
    visible?: boolean;
  }[];
}) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 120 }}>
        {rowActions.map((rowAction, index) => {
          return (
            <Fragment key={index}>
              {rowAction.visible && (
                <MenuItem
                  onClick={rowAction.action}
                  disabled={rowAction.disabled}
                  color={rowAction.color}
                >
                  {rowAction.label}
                </MenuItem>
              )}
            </Fragment>
          );
        })}
      </Menu>
    </Dropdown>
  );
}

export default function FilesTable() {
  const { t } = useTranslation('common');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const { user, company } = useAppState();
  const isCompanyAdmin = company?.admin?.includes(user?.uid ?? '');

  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [selectedDossierId, setSelectedDossierId] = useState<string | null>(
    null
  );
  const [newName, setNewName] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLButtonElement>(null);

  const { companyId, tagId } = useParams();
  const tags = useAppState((state) => state.tags);
  const navigate = useNavigate();

  useEffect(() => {
    if (tags.length > 0 && tagId === 'all') {
      const defaultTag = tags[0].id;
      navigate(`/company/${companyId}/dossiers/tag/${defaultTag}`);
    }
  }, [tags, tagId, companyId, navigate]);

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultArchived = searchParams.get('archived') === 'true';

  const {
    setOrder,
    setOrderBy,
    setSearch,
    order,
    orderBy,
    dossiers,
    isFetching,
    canGoNextPage,
    canGoPreviousPage,
    goPreviousPage,
    goNextPage,
  } = useDossiers(companyId!, tagId ?? '', defaultArchived);

  const onRequestSort = (
    // @ts-ignore
    event: MouseEvent<unknown>,
    property: keyof IDossier
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // sort columns by "orderBy"
  const createSortHandler =
    (property: keyof IDossier) => (event: MouseEvent<unknown>) => {
      return onRequestSort(event, property);
    };

  // fetch files

  const queryClient = useQueryClient();
  const handleSearchFile = (searched: string) => setSearch(searched);

  // Mutations
  const { mutate } = useMutation({
    // @ts-ignore
    mutationFn: async (updated: {
      dossierId: string;
      data: any;
      action: string;
      name: string;
    }) => {
      await updateDossier(updated.dossierId, updated.data);
    },
    // @ts-ignore
    onSuccess: async (data: string, variables: any) => {
      // Invalidate and refetch
      await queryClient.invalidateQueries({
        queryKey: [LIST_DOSSIERS_QUERY_KEY],
      });
      enqueueSnackbar(
        t(`dossier.actions.${variables.action}`, { name: variables.name }),
        {
          variant: 'success',
          autoHideDuration: 1500,
        }
      );
    },
    onError: (error, variables: any) => {
      console.error(error);
      enqueueSnackbar(t(`dossier.actions.error.${variables.action}`), {
        variant: 'error',
        autoHideDuration: 1000,
      });
    },
  });

  const onEndEdit = () => {
    setEditMode(false);
    setSelectedDossierId(null);
    setNewName(null);
    setSelectedStatus(null);
  };

  const handleClickToOpenDossier = useCallback(
    (dossierId: string) => {
      if (isEditMode) return;
      // open tab to the dossier
      window.open(
        `/company/${companyId}/dossier/${dossierId}`,
        '_blank',
        'noreferrer'
      );
    },
    [isEditMode, companyId]
  );

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape' && isEditMode && selectedDossierId) {
        onEndEdit();
      }
    };

    const handleEnterKey = (event: any) => {
      const isOutsideClick =
        !textInputRef.current?.contains(event.target) &&
        !statusRef.current?.contains(event.target);

      const shouldUpdate =
        (event.key === 'Enter' || isOutsideClick) &&
        isEditMode &&
        selectedDossierId;
      if (shouldUpdate) {
        const isNameValid = newName && checkNewName(dossiers, newName, t);

        if (isNameValid && !selectedStatus) {
          mutate({
            dossierId: selectedDossierId,
            data: { name: newName },
            action: 'rename',
            name: newName,
          });
          onEndEdit();
        }
        if (selectedStatus && !isNameValid) {
          mutate({
            dossierId: selectedDossierId,
            data: { status: selectedStatus },
            action: 'editStatus',
            name: dossiers.find((d) => d.id === selectedDossierId)!.name,
          });
          onEndEdit();
        }
        if (selectedStatus && isNameValid) {
          mutate({
            dossierId: selectedDossierId,
            data: { status: selectedStatus, name: newName },
            action: 'editStatusAndRename',
            name: newName,
          });
          onEndEdit();
        }

        event.preventDefault(); // Prevent the default action to stop form submission or any other unwanted behavior
      }
    };

    document.body.addEventListener('click', handleEnterKey);
    document.addEventListener('keydown', handleEscapeKey);
    document.addEventListener('keydown', handleEnterKey);

    return () => {
      document.body.removeEventListener('click', handleEnterKey);
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [
    isEditMode,
    selectedDossierId,
    newName,
    mutate,
    dossiers,
    selectedStatus,
    t,
  ]);

  const handleEditDossier = (dossierId: string) => {
    setEditMode(true);
    setSelectedDossierId(dossierId);
  };
  const handleDeleteDossier = (dossierId: string) => {
    deleteDossier(dossierId).then(
      async () =>
        await queryClient.invalidateQueries({
          queryKey: [LIST_DOSSIERS_QUERY_KEY],
        }),
      (error) => console.error(error)
    );
  };

  const handleArchiveDossier = (
    dossierId: string,
    archived: boolean,
    name: string
  ) => {
    const dataToUpdate = archived
      ? { archived: false, archivedBy: null, archivedAt: null }
      : {
          archived: true,
          archivedBy: user?.email,
          archivedAt: serverTimestamp(),
        };

    mutate({
      dossierId,
      data: dataToUpdate,
      action: archived ? 'unarchive' : 'archive',
      name,
    });
  };

  const handleStatusChange = (
    // @ts-ignore
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    setSelectedStatus(newValue);
  };

  const rowActions = (dossier: IDossier) => [
    {
      label: t('common.actions.edit'),
      action: () => handleEditDossier(dossier.id),
      disabled: false,
      color: 'neutral' as const,
      visible: true,
    },
    {
      label: dossier?.archived
        ? t('common.actions.unarchive')
        : t('common.actions.archive'),
      action: () =>
        handleArchiveDossier(
          dossier.id,
          dossier?.archived ?? false,
          dossier.name
        ),
      disabled: false,
      color: 'danger' as const,
      visible: true,
    },
    {
      label: t('common.actions.delete'),
      action: () => handleDeleteDossier(dossier.id),
      disabled: !isCompanyAdmin,
      color: 'danger' as const,
      visible: isCompanyAdmin,
    },
  ];

  return (
    <Fragment>
      <Stack direction="row" justifyContent="space-between">
        <SearchBar
          placeholder="Cherchez un dossier par son nom"
          handleChange={handleSearchFile}
        />

        <Switch
          checked={defaultArchived}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchParams(
              createSearchParams({ archived: event.target.checked.toString() })
            )
          }
          color={defaultArchived ? 'primary' : 'neutral'}
          variant="outlined"
          slotProps={{
            thumb: {
              children: <ArchiveIcon />,
            },
          }}
          sx={{
            '--Switch-thumbSize': '24px',
            '--Switch-trackHeight': '30px',
            '--Switch-trackWidth': '50px',
            '--Switch-trackRadius': '16px',
            marginLeft: '1rem',
          }}
        />
      </Stack>

      {isFetching ? (
        <Stack alignItems={'center'} justifyContent={'center'} height={200}>
          <CircularProgress></CircularProgress>
        </Stack>
      ) : dossiers.length === 0 ? (
        <Typography level="title-md" sx={{ mt: 2 }}>
          {t('dossier.list.empty')}
        </Typography>
      ) : (
        <Stack gap={2}>
          <Sheet
            className="FileTableContainer"
            variant="outlined"
            sx={{
              maxHeight: '90vh',
              width: '100%',
              borderRadius: 'sm',
              flexShrink: 1,
              overflow: 'auto',
              '--Table-lastColumnWidth': '136px',
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground':
                  'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground':
                  'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
                '& tr > *:last-child': {
                  position: 'sticky',
                  right: 0,
                  bgcolor: 'var(--TableCell-headBackground)',
                  textAlign: 'right',
                },
              }}
              size="md"
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: 48,
                      textAlign: 'center',
                      padding: '12px 6px',
                    }}
                  >
                    <Checkbox
                      size="sm"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length !== dossiers.length
                      }
                      checked={selected.length === dossiers.length}
                      onChange={(event) => {
                        setSelected(
                          event.target.checked
                            ? dossiers.map((row: IDossier) => row?.name)
                            : []
                        );
                      }}
                      color={
                        selected.length > 0 ||
                        selected.length === dossiers.length
                          ? 'primary'
                          : undefined
                      }
                      sx={{ verticalAlign: 'text-bottom' }}
                    />
                  </th>

                  {headCells(t).map((headCell) => {
                    const active = orderBy === headCell.id;

                    const widthColumn = headCell.id == 'size' ? 120 : 160;
                    return (
                      <Fragment key={headCell.id}>
                        <th
                          style={{
                            width: widthColumn,
                            padding: '12px 6px',
                          }}
                          aria-sort={
                            active
                              ? order === 'asc'
                                ? 'ascending'
                                : 'descending'
                              : 'none'
                          }
                        >
                          <JoyLink
                            underline="none"
                            color="neutral"
                            textColor={
                              active ? 'primary.plainColor' : undefined
                            }
                            component="button"
                            onClick={createSortHandler(headCell.id)}
                            fontWeight="lg"
                            endDecorator={
                              <ArrowDownwardIcon
                                sx={{ opacity: active ? 1 : 0 }}
                              />
                            }
                            sx={{
                              '& svg': {
                                transition: '0.2s',
                                transform:
                                  active && order === 'desc'
                                    ? 'rotate(0deg)'
                                    : 'rotate(180deg)',
                              },
                              '&:hover': { '& svg': { opacity: 1 } },
                            }}
                          >
                            <Typography
                              color={active ? 'primary' : 'neutral'}
                              level="title-sm"
                              variant="plain"
                              sx={{ fontWeight: '600' }}
                            >
                              {headCell.label}
                            </Typography>
                            {active ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </JoyLink>
                        </th>
                        {/* We add an extra th that will be of dynamic width and play with colspan to hack around */}
                        <th></th>
                      </Fragment>
                    );
                  })}

                  <th
                    style={{
                      width: 'var(--Table-lastColumnWidth)',
                      padding: '12px 6px',
                    }}
                    aria-label="last"
                  ></th>
                </tr>
              </thead>

              <tbody>
                {
                  // @ts-ignore
                  dossiers.map((row: IDossier) => {
                    const hasWorkflow = row?.workflowStatus;
                    const canOpenDossier = !!hasWorkflow;

                    return (
                      <tr
                        key={row?.id}
                        onClick={() => {
                          if (canOpenDossier) handleClickToOpenDossier(row.id);
                        }}
                        style={{
                          cursor:
                            canOpenDossier && !isEditMode
                              ? 'pointer'
                              : 'default',
                        }}
                      >
                        <td
                          style={{ textAlign: 'center', cursor: 'default' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            size="sm"
                            checked={selected.includes(row?.name)}
                            color={
                              selected.includes(row?.name)
                                ? 'primary'
                                : undefined
                            }
                            onChange={(event) => {
                              setSelected((ids) =>
                                event.target.checked
                                  ? ids.concat(row?.name)
                                  : ids.filter((itemId) => itemId !== row?.name)
                              );
                            }}
                            slotProps={{
                              checkbox: { sx: { textAlign: 'left' } },
                            }}
                            sx={{ verticalAlign: 'text-bottom' }}
                          />
                        </td>
                        <td colSpan={2}>
                          {isEditMode && selectedDossierId === row?.id ? (
                            // Render the input field because we're in edit mode for this row
                            <Input
                              ref={textInputRef}
                              type="text"
                              value={newName ?? row?.name}
                              onChange={(e) => setNewName(e.target.value)}
                              sx={{ width: '100%' }}
                              autoFocus
                            />
                          ) : (
                            <Typography level="title-sm">
                              {row?.name}
                            </Typography>
                          )}
                        </td>
                        <td colSpan={2}>
                          {isEditMode && selectedDossierId === row?.id ? (
                            <Select
                              color="primary"
                              variant="outlined"
                              ref={statusRef}
                              size="sm"
                              value={selectedStatus}
                              onChange={handleStatusChange}
                              placeholder={t('dossier.statusPlaceholder')}
                            >
                              {Object.values(DossierStatus).map((status) => (
                                <Option key={status} value={status}>
                                  {t(`dossier.statuses.${status}`)}
                                </Option>
                              ))}
                            </Select>
                          ) : (
                            <Chip
                              variant="soft"
                              size="md"
                              sx={{ display: { xs: 'none', sm: 'flex' } }}
                              startDecorator={
                                {
                                  [DossierStatus.NEW]: (
                                    <HourglassEmptyRoundedIcon />
                                  ),
                                  [DossierStatus.IN_PROGRESS]: (
                                    <HourglassTopRoundedIcon />
                                  ),
                                  [DossierStatus.VALID]: <CheckRoundedIcon />,
                                  [DossierStatus.INCOMPLETE]: (
                                    <AutorenewRoundedIcon />
                                  ),
                                  [DossierStatus.REJECTED]: <BlockIcon />,
                                  [DossierStatus.INVALID]: <ErrorRoundedIcon />,
                                }[row.status] as any
                              }
                              color={
                                {
                                  [DossierStatus.NEW]: 'neutral',
                                  [DossierStatus.VALID]: 'success',
                                  [DossierStatus.IN_PROGRESS]: 'primary',
                                  [DossierStatus.INCOMPLETE]: 'warning',
                                  [DossierStatus.REJECTED]: 'danger',
                                  [DossierStatus.INVALID]: 'danger',
                                }[row.status] as any
                              }
                            >
                              {row?.status
                                ? t(`dossier.statuses.${row.status}`)
                                : ''}
                            </Chip>
                          )}
                        </td>

                        <td colSpan={2}>
                          <Typography level="title-sm">
                            {formatTimeStamp(row?.createdAt)}
                          </Typography>
                        </td>
                        <td colSpan={2}>
                          <Typography level="title-sm">
                            {formatTimeStamp(row?.updatedAt)}
                          </Typography>
                        </td>
                        <td colSpan={2}>
                          <Typography level="title-sm">
                            {row?.size && formatSize(row?.size)}
                          </Typography>
                        </td>
                        <td colSpan={2}>
                          <Typography level="title-sm">
                            {row?.createdBy && formatUserEmail(row?.createdBy)}
                          </Typography>
                        </td>
                        <td colSpan={2}>
                          <Typography level="title-sm">
                            {formatTimeStamp(row?.archivedAt)}
                          </Typography>
                        </td>
                        <td
                          style={{
                            width: 'var(--Table-lastColumnWidth)',
                            cursor: 'default',
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 2,
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              loading={
                                !canOpenDossier ||
                                row?.workflowStatus === WorkflowStatus.STARTED
                              }
                              onClick={() => {
                                if (canOpenDossier)
                                  handleClickToOpenDossier(row.id);
                              }}
                              variant="soft"
                              size="sm"
                              disabled={!canOpenDossier}
                            >
                              {t('common.actions.open')}
                            </Button>

                            <RowMenu rowActions={rowActions(row)} />
                          </Box>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Sheet>
          <Stack direction={'row'} justifyContent={'center'} gap={1}>
            <Button
              disabled={!canGoPreviousPage}
              variant={'outlined'}
              onClick={goPreviousPage}
            >
              Page précédente
            </Button>
            <Button
              disabled={!canGoNextPage}
              variant={'outlined'}
              onClick={goNextPage}
            >
              Page suivante
            </Button>
          </Stack>
        </Stack>
      )}
      <TrackTiming name="FilesTable Loaded" />
    </Fragment>
  );
}
