import { collection, getDocs, query, where } from 'firebase/firestore';

import { db } from 'config/firebaseInit';
import { CompanyZod, ICompany } from 'types/index';

export const getUserCompanies = async (userId: string): Promise<ICompany[]> => {
  const companyRef = collection(db, 'companies');
  const allData = await getDocs(
    query(companyRef, where('members', 'array-contains', userId))
  );
  return allData.docs.map((doc) =>
    CompanyZod.parse({ id: doc.id, ...doc.data() })
  );
};
