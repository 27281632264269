import { json, redirect } from 'react-router-dom';

import { firebaseAuthProvider } from 'queries/authProvider';
import { getUserCompanies } from 'queries/company';
import { getCompanyDefaultTag } from 'queries/tags';

export async function loginAction({ request }: any) {
  const formData = await request.formData();
  const email = formData.get('email') as string | null;
  const password = formData.get('password') as string | null;

  // Validate our form inputs and return validation errors via useActionData()
  if (!email || !password) {
    if (!email) {
      return {
        error: 'You must provide a email to log in',
      };
    }
    if (!password) {
      return {
        error: 'You must provide a password to log in',
      };
    }
  }
  const response = await firebaseAuthProvider
    .signinWithPassword(email, password, async (user) => {
      const companies = await getUserCompanies(user?.uid);
      if (companies.length === 0) {
        throw new Error('No company found for this user');
      }
    })
    .catch((err) => {
      console.error('in login', err);
      return {
        company: null,
        error: {
          code: err.code,
          message: err.message,
          severity: 'error',
        },
      };
    });

  // @ts-ignore
  if (response?.company && response?.company?.id) {
    const tags = await getCompanyDefaultTag(response?.company?.id);
    const defaultTag = tags && tags.length > 0 ? tags![0].id : 'all';
    return redirect(
      `/company/${response?.company?.id}/dossiers/tag/${defaultTag}`
    );
  } else return json({ data: response?.error }, { status: 401 });
}

export async function passwordResetAction({ request }: any) {
  const formData = await request.formData();
  const email = formData.get('email') as string | null;
  // Validate our form inputs and return validation errors via useActionData()
  if (!email) {
    return {
      error: 'You must provide an email to reset your password',
    };
  }
  await firebaseAuthProvider
    .passwordReset(email)
    .catch((err) => console.error('in password reset', err));

  return redirect('/login');
}
