import BlurOn from '@mui/icons-material/BlurOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Tooltip } from '@mui/joy';
import CircularProgress from '@mui/joy/CircularProgress';

import { useInteractionsState } from 'stores/interactionsStore.ts';

export function ReviewStatus() {
  const metaDossierInfoIsLoading = useInteractionsState(
    (state) => state.metaDossierInfoIsLoading
  );
  const { reviewed, isValid } = useInteractionsState(
    (state) =>
      state.metaDossierInfo?.review || { reviewed: null, isValid: null }
  );

  if (metaDossierInfoIsLoading) {
    return <CircularProgress size={'sm'}></CircularProgress>;
  }
  if (reviewed) {
    if (isValid) {
      return (
        <Tooltip title={'Document validé'}>
          <CheckRoundedIcon sx={{ fontSize: '2rem' }} color={'success'} />
        </Tooltip>
      );
    } else if (!isValid) {
      return (
        <Tooltip title={'Document invalide'}>
          <CancelIcon sx={{ fontSize: '2rem' }} color={'warning'} />
        </Tooltip>
      );
    } else {
      console.warn("Weird case, shouldn't happen");
    }
  }
  return (
    <Tooltip title={'Document pas encore revu'}>
      <BlurOn sx={{ fontSize: '2rem' }} color={'info'} />
    </Tooltip>
  );
}
