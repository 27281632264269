import { Core } from '@pdftron/webviewer';

import { formatDate, formatPDFNetDate } from './formatDates';
//---------------------------------------------------------------------------------------
// https://docs.apryse.com/documentation/samples/js/DigitalSignaturesTest/

export const VerifyAllAndPrint = async (
  doc: Core.PDFNet.PDFDoc,
  PDFNet: typeof Core.PDFNet
) => {
  // We create a separate doc to handle the security checks
  await doc.initSecurityHandler();
  const { VerificationResult } = PDFNet;
  const {
    TrustStatus,
    DigestStatus,
    ModificationPermissionsStatus,
    DocumentStatus,
  } = VerificationResult;

  const opts = await PDFNet.VerificationOptions.create(
    PDFNet.VerificationOptions.SecurityLevel.e_compatibility_and_archiving
  );

  let digsig_fitr;
  // Iterate over the signatures and verify all of them.
  try {
    digsig_fitr = await doc.getDigitalSignatureFieldIteratorBegin();
  } catch (error) {
    console.error('Error getting digital signature field iterator:', error);
    return [];
  }

  const signaturesResults = [];
  while (await digsig_fitr.hasNext()) {
    await digsig_fitr.next();
    const digitalSigField = await digsig_fitr.current();
    const result = await digitalSigField.verify(opts);

    const verificationStatus = await result.getVerificationStatus();
    const idSign = await (await digitalSigField.getSDFObj()).getObjNum();

    let signer;
    let validAtTimeOfSigning;
    let signerName;
    const issuerField = {};
    const subjectField = {};
    let formatedSignTime;

    const contactInfo = await digitalSigField.getContactInfo();
    const location = await digitalSigField.getLocation();
    const reason = await digitalSigField.getReason();
    const documentPermission = await digitalSigField.getDocumentPermissions();
    const isCertification = await digitalSigField.isCertification();

    const signed = await digitalSigField.hasCryptographicSignature();

    if (!signer) {
      signer =
        (await digitalSigField.getSignatureName()) ||
        (await digitalSigField.getContactInfo());
    }

    const signTime = await digitalSigField.getSigningTime();

    if (await signTime.isValid()) {
      formatedSignTime = formatPDFNetDate(
        signTime, //currentLanguage ||
        'fr'
      );
    } else {
      formatedSignTime = null;
    }

    const documentStatus = await result.getDocumentStatus();
    const digestStatus = await result.getDigestStatus();
    const trustStatus = await result.getTrustStatus();
    const permissionStatus = await result.getPermissionsStatus();
    const digestAlgorithm = await result.getDigestAlgorithm();
    const disallowedChanges = [];
    for (const change of await result.getDisallowedChanges()) {
      disallowedChanges.push({
        objnum: await change.getObjNum(),
        type: await change.getTypeAsString(),
      });
    }

    const validSignerIdentity = trustStatus === TrustStatus.e_trust_verified;

    // Get and print all the detailed trust-related results, if they are available.
    let trustVerificationResultString;
    let trustVerificationResultBoolean;
    let timeOfTrustVerificationEnum;
    let trustVerificationTime;
    const hasTrustVerificationResult =
      await result.hasTrustVerificationResult();

    if (hasTrustVerificationResult) {
      const trustVerificationResult = await result.getTrustVerificationResult();

      trustVerificationResultBoolean =
        await trustVerificationResult.wasSuccessful();
      trustVerificationResultString =
        await trustVerificationResult.getResultString();
      timeOfTrustVerificationEnum =
        await trustVerificationResult.getTimeOfTrustVerificationEnum();

      const epochTrustVerificationTime =
        await trustVerificationResult.getTimeOfTrustVerification();
      if (epochTrustVerificationTime) {
        trustVerificationTime = formatDate(
          epochTrustVerificationTime,
          'fr' //currentLanguage
        );
      }
    }

    let badgeIcon;
    if (verificationStatus) {
      badgeIcon = 'digital_signature_valid';
    } else if (
      documentStatus === DocumentStatus.e_no_error &&
      (digestStatus === DigestStatus.e_digest_verified ||
        digestStatus === DigestStatus.e_digest_verification_disabled) &&
      trustStatus !== TrustStatus.e_no_trust_status &&
      (permissionStatus === ModificationPermissionsStatus.e_unmodified ||
        permissionStatus ===
          ModificationPermissionsStatus.e_has_allowed_changes ||
        permissionStatus ===
          ModificationPermissionsStatus.e_permissions_verification_disabled)
    ) {
      badgeIcon = 'digital_signature_warning';
    } else {
      badgeIcon = 'digital_signature_error';
    }

    signaturesResults.push({
      signed,
      signer,
      signerName,
      signTime,
      verificationStatus,
      documentStatus,
      digestStatus,
      trustStatus,
      permissionStatus,
      disallowedChanges,
      trustVerificationResultBoolean,
      trustVerificationResultString,
      timeOfTrustVerificationEnum,
      trustVerificationTime,
      id: idSign,
      badgeIcon,
      validSignerIdentity,
      digestAlgorithm,
      documentPermission,
      isCertification,
      contactInfo,
      location,
      reason,
      issuerField,
      subjectField,
      validAtTimeOfSigning,
      formatedSignTime,
    });
  }
  return signaturesResults;
};
