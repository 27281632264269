import { Core, WebViewerInstance } from '@pdftron/webviewer';

import i18n from 'i18n.ts';
import { IDocType, IDocTypesByFileByPage } from 'types/index';
import { docTypeOrderInOutline } from 'utils/constants';
import { reOrderDocTypes } from 'utils/reorderDocumentPages';

/**
 * Function to create a bookmark with the specified name and set its action to go to the specified page.
 *
 * @param {any} pdfDoc - The PDF document instance.
 * @param {any} PDFNet - The PDFNet instance.
 * @param {string} outlineName - The name of the bookmark to be created.
 * @param {number} pageNumber - The page number the bookmark should point to.
 * @returns {Promise<any>} - A promise that resolves to the created bookmark item.
 */
async function createBookmarkWithAction(
  pdfDoc: Core.PDFNet.PDFDoc,
  PDFNet: typeof Core.PDFNet,
  outlineName: string,
  pageNumber: number
) {
  const myitem = await PDFNet.Bookmark.create(pdfDoc, outlineName);
  const pageNumberInInstance = await pdfDoc.getPage(pageNumber);
  await myitem.setAction(
    await PDFNet.Action.createGoto(
      await PDFNet.Destination.createFit(pageNumberInInstance)
    )
  );

  return myitem;
}

/**
 * Function to add a root bookmark (outline) to a PDF document.
 *
 * @param {any} pdfDoc - The PDF document instance.
 * @param {any} PDFNet - The PDFNet instance.
 * @param {string} outlineName - The name of the bookmark to be added.
 * @param {number} pageNumber - The page number the bookmark should point to.
 * @returns {Promise<any>} - A promise that resolves to the created bookmark item.
 */
export async function addOneRootOutline(
  pdfDoc: Core.PDFNet.PDFDoc,
  PDFNet: typeof Core.PDFNet,
  outlineName: string,
  pageNumber: number
) {
  const myitem = await createBookmarkWithAction(
    pdfDoc,
    PDFNet,
    outlineName,
    pageNumber
  );
  await pdfDoc.addRootBookmark(myitem);
  return myitem;
}

/**
 * Function to add a child bookmark (outline) under a specified parent bookmark in a PDF document.
 *
 * @param {any} pdfDoc - The PDF document instance.
 * @param {any} PDFNet - The PDFNet instance.
 * @param {any} parentBookmark - The parent bookmark under which the child bookmark should be added.
 * @param {string} outlineName - The name of the child bookmark to be added.
 * @param {number} pageNumber - The page number the child bookmark should point to.
 * @returns {Promise<void>} - A promise that resolves when the child bookmark is added.
 */
export async function addOneChildOutline(
  pdfDoc: Core.PDFNet.PDFDoc,
  PDFNet: typeof Core.PDFNet,
  parentBookmark: any,
  outlineName: string,
  pageNumber: number
) {
  const childItem = await createBookmarkWithAction(
    pdfDoc,
    PDFNet,
    outlineName,
    pageNumber
  );

  await parentBookmark?.addChild(childItem);
  return childItem;
}

const sortDoctypeByFileByPage = (
  docTypesByFileByPage: IDocTypesByFileByPage
): IDocTypesByFileByPage => {
  // Iterate over the keys of the docTypesByFileByPage object
  const sorted = Object.keys(docTypesByFileByPage).reduce((acc, docType) => {
    // Ensure the docType is of type IDocType
    const typedDocType = docType as IDocType;

    // Get the entries for the current docType
    const entries = Object.entries(docTypesByFileByPage[typedDocType]!);

    // Sort the entries based on the minimum page number
    const sortedEntries = entries.sort(
      ([, pagesA], [, pagesB]) => Math.min(...pagesA) - Math.min(...pagesB)
    );

    // Add the sorted entries to the accumulator
    acc[typedDocType] = Object.fromEntries(sortedEntries);

    return acc;
  }, {} as IDocTypesByFileByPage);

  return sorted;
};

/**
 * Function to compare and add bookmarks to a PDF document based on the document types and files.
 * Creates hierarchical outlines with root bookmarks for document types and child bookmarks for files.
 *
 * @param {any} doc - The PDF document instance.
 * @param {any} PDFNet - The PDFNet instance.
 * @param {IDocTypesByFileByPage} docTypesByFileByPage - An object mapping document types to files and their pages.
 * @param {any} t - Translation function for localizing document type names.
 * @param remappedPageNumber - Page number mapping that is used for displaying
 * @returns {Promise<boolean[]>} - A promise that resolves to an array indicating which document types had bookmarks added.
 */
export const resetBookmarksAndCreateFromDocTypesByFileByPage = async ({
  doc,
  PDFNet,
  docTypesByFileByPage,
  docExtraLabelByDocKey,
}: {
  doc: Core.Document;
  PDFNet: typeof Core.PDFNet;
  docTypesByFileByPage: IDocTypesByFileByPage;
  docExtraLabelByDocKey: Record<string, string>;
}) => {
  // Retrieve the PDF document and its bookmarks
  const pdfDoc = await doc.getPDFDoc();

  // Remove all known bookmarks from the document (all changes here will be saved later)
  let bookmark;
  // eslint-disable-next-line no-cond-assign
  while ((bookmark = await pdfDoc.getFirstBookmark())) {
    await bookmark.delete();
  }

  // Sort and order the document types
  const sortedDocTypes = sortDoctypeByFileByPage(docTypesByFileByPage);
  const orderedDocTypes = reOrderDocTypes(
    Object.keys(sortedDocTypes) as IDocType[],
    docTypeOrderInOutline
  );

  const added = [];
  for (const docType of orderedDocTypes) {
    const translatedDocType = i18n.t(`docTypes.${docType}.name`);
    const files = sortedDocTypes[docType];
    added.push(
      await addRootAndChildOutlines({
        bookMarkAdded: false,
        pdfDoc,
        PDFNet,
        files,
        translatedDocType,
        docExtraLabelByDocKey,
      })
    );
  }
  return added;
};

const addRootAndChildOutlines = async ({
  bookMarkAdded,
  pdfDoc,
  PDFNet,
  files,
  translatedDocType,
  docExtraLabelByDocKey,
}: {
  bookMarkAdded: boolean;
  pdfDoc: Core.PDFNet.PDFDoc;
  PDFNet: typeof Core.PDFNet;
  files: any;
  translatedDocType: any;
  docExtraLabelByDocKey: Record<string, string>;
}) => {
  // Add root bookmark for the document type
  const firstPageNumber = files[Object.keys(files)[0]][0];

  const rootBookmark = await addOneRootOutline(
    pdfDoc,
    PDFNet,
    translatedDocType,
    firstPageNumber
  ).catch((err) => console.error('error in addOneRootOutline', err));

  if (rootBookmark) {
    bookMarkAdded = true;

    // Add child bookmarks for each file, pointing to the first page of the file
    for (const fileName in files) {
      const pages = files[fileName];
      const firstPageOfFile = pages[0];
      const childOutlineName = `${translatedDocType} ${docExtraLabelByDocKey[fileName]}`;

      await addOneChildOutline(
        pdfDoc,
        PDFNet,
        rootBookmark,
        childOutlineName,
        firstPageOfFile
      ).catch((err) => console.error('error in addOneChildOutline', err));
    }
  }
  return bookMarkAdded;
};

export const buildOutline = async (
  instance: WebViewerInstance,
  docTypesByFileByPage: IDocTypesByFileByPage,
  docExtraLabelByDocKey: Record<string, string>
) => {
  const { Core, UI } = instance;
  const { documentViewer, PDFNet } = Core;
  const { reloadOutline } = UI;
  const doc = documentViewer.getDocument();

  // Compare the bookmarks with the doc types split By Page
  const bookMarksAdded = await resetBookmarksAndCreateFromDocTypesByFileByPage({
    doc,
    PDFNet,
    docTypesByFileByPage,
    docExtraLabelByDocKey,
  });

  if (bookMarksAdded.some(Boolean)) {
    await documentViewer.getDocument().getBookmarks();
    // Reloads the Bookmark Outline in the WebViewer UI              }
    reloadOutline();
  }
};

export const updateOutlineSelected = async (
  doc: Core.Document,
  targetPageNumber: number
) => {
  // A bit of algorithms to go through the bookmarks tree and find the closest bookmark to the target page number
  // the idea is that in the html bookmarks order will be coherent when doing
  // .getElementsByClassName(
  //     'bookmark-outline-single-container'
  //   )
  let i = -1;
  let closestPageNumber: number | null = null;
  let closestBookmarkIdx: number | null = null;

  // This will be recursive but with an external state (yeah not very clean but it works)
  // FIXME: refactor this
  const findClosestBookmarkIdx = (children: Core.Bookmark[]) => {
    if (children.length === 0) {
      return;
    }
    for (const child of children) {
      i = i + 1;
      const childChildren = child.getChildren();
      if (childChildren.length === 0) {
        const childPageNumber = child.getPageNumber();
        if (childPageNumber <= targetPageNumber) {
          if (closestPageNumber === null) {
            closestPageNumber = childPageNumber;
            closestBookmarkIdx = i;
          } else {
            if (childPageNumber > closestPageNumber) {
              closestPageNumber = childPageNumber;
              closestBookmarkIdx = i;
            }
          }
        }
      } else {
        findClosestBookmarkIdx(childChildren);
      }
    }
  };
  findClosestBookmarkIdx(await doc.getBookmarks());

  // Finally we hijack apryse window to fix its content :nerd:
  [
    ...document
      .getElementsByTagName('iframe')
      .item(0)!
      .contentWindow!.document.getElementsByClassName(
        'bookmark-outline-single-container'
      ),
  ].forEach((elem, idx) => {
    if (idx === closestBookmarkIdx) {
      elem.classList.add('selected');
    } else {
      elem.classList.remove('selected');
    }
  });
};
