import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { PageSizes } from 'pdf-lib';

import { checkAnnotationShouldBeSaved } from 'utils/annotations.ts';

/**
 * Get file data with annotations from the webviewer instance
 * @param instance
 */
export const getFileData = async (
  instance: WebViewerInstance
): Promise<Uint8Array> => {
  const { documentViewer, annotationManager } = instance.Core;
  const doc = documentViewer.getDocument();

  const annotationsToKeep = annotationManager
    .getAnnotationsList()
    .filter((el) => checkAnnotationShouldBeSaved(el));

  // We get the annotations string for the annotations to keep
  const xfdfString = await annotationManager.exportAnnotations({
    annotationList: annotationsToKeep,
  });

  const fileData = await doc.getFileData({
    xfdfString,
    includeAnnotations: true,
    // We linearize the PDF to optimize it a tiny bit and remove dead objects
    flags: instance.Core.SaveOptions.LINEARIZED,
  });

  // We avoid returning a buffer not to complexify the result of this function
  // Yes, we might use a bit more memory then.
  return new Uint8Array(fileData);
};

/**
 * Helper method that will rescale the width of the pages to the a4 width
 */
export const rescalePagesWidthToA4 = async (
  doc: Core.PDFNet.PDFDoc,
  pagesIdx: number[]
) => {
  doc.lock();
  for (const pageNumber of pagesIdx) {
    const page = await doc.getPage(pageNumber);
    const width = await page.getPageWidth();

    // rotation is already taken into account in width,
    // so we don't need to do a fancy logic here
    const ratio = PageSizes.A4[0] / width;

    await page.scale(ratio);
  }
};
