import { WebViewerInstance } from '@pdftron/webviewer';
import { enqueueSnackbar } from 'notistack';
import { z } from 'zod';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { updateAbricoMetaDossier } from 'cloudFunctions/functions.ts';
import i18n from 'i18n.ts';
import { queryClient } from 'queries';
import { getMetaDossierQueryKey } from 'queries/dossiers.ts';
import {
  AnnotStatus,
  IDossierLock,
  TInteractionsFS,
  TMetaDossierResponse,
  TMetaDossierWipElByMetaKey,
  TSyncValue,
} from 'types/index';
import { TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS } from 'utils/constants.ts';
import { Dossier } from 'utils/dossier.ts';

const disableEditElementsAndFeatures = (instance: WebViewerInstance) => {
  const { disableFeatures, disableElements, Feature } = instance.UI;

  disableElements(TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS);
  disableFeatures([Feature.ThumbnailReordering, Feature.ThumbnailMerging]);
  instance.Core.annotationManager.enableReadOnlyMode();
};

const enableEditElementsAndFeatures = (instance: WebViewerInstance) => {
  const { enableFeatures, enableElements, Feature } = instance.UI;

  enableElements(TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS);
  enableFeatures([Feature.ThumbnailReordering, Feature.ThumbnailMerging]);
  instance.Core.annotationManager.disableReadOnlyMode();
};

interface InteractionsState {
  getDataForFs: () => TInteractionsFS;
  mergeData: (data: TInteractionsFS) => void;
  instance: WebViewerInstance;
  setInstance: (instance: WebViewerInstance) => void;
  dossier: Dossier;
  setDossier: (dossier: Dossier) => void;
  openedInRWMode: boolean | null;
  setOpenedInRWMode: (opened: boolean) => void;
  roModalOpen: boolean;
  setRoModalOpen: (open: boolean) => void;
  lockInfo: IDossierLock | null;
  setLockInfo: (lockInfo: IDossierLock) => void;
  isInReadOnlyMode: boolean;
  isTemporaryReadOnlyMode: boolean;
  enableReadOnlyMode: (temporary?: boolean) => void;
  disableReadOnlyMode: () => void;
  hasChangesThatRequireSave: boolean;
  setHasChangesThatRequireSave: (hasChanges: boolean) => void;
  entitiesStatuses: Map<string, AnnotStatus>;
  setEntityStatus: (
    id: string,
    targetStatus: AnnotStatus,
    force?: boolean
  ) => void;
  getEntityStatus: (id: string) => AnnotStatus;
  entitiesRejectionReasons: Map<string, string>;
  setEntityRejectionReason: (entityId: string, reason: string) => void;
  getEntityRejectionReason: (entityId: string) => string;
  metaDossierInfoIsLoading: boolean;
  setMetaDossierInfoIsLoading: (isLoading: boolean) => void;
  metaDossierInfo: TMetaDossierResponse | undefined;
  setMetaDossierInfo: (metaDossierInfo: TMetaDossierResponse) => void;
  metaDossierWipElByMetaKey: TMetaDossierWipElByMetaKey;
  setMetaDossierWipEl: (metaKey: string, value: TSyncValue) => void;
  metaDossierChanges: Record<string, TSyncValue>;
  setMetaDossierChanges: (
    metaDossierChanges: Record<string, TSyncValue>
  ) => void;
  saveNewMetaDossierToAbrico: () => Promise<void>;
  isSavingNewMetaDossierToAbrico: boolean;
}

export const useInteractionsState = create<InteractionsState>()(
  devtools(
    (set, get) => ({
      getDataForFs: (): TInteractionsFS => {
        return {
          entitiesStatuses: Object.fromEntries(
            get().entitiesStatuses.entries()
          ),
          entitiesRejectionReasons: Object.fromEntries(
            get().entitiesRejectionReasons.entries()
          ),
        };
      },
      mergeData(data: TInteractionsFS) {
        set(() => ({
          entitiesStatuses: new Map([
            ...get().entitiesStatuses.entries(),
            ...Object.entries(data.entitiesStatuses || {}),
          ]),
          entitiesRejectionReasons: new Map([
            ...get().entitiesRejectionReasons.entries(),
            ...Object.entries(data.entitiesRejectionReasons || {}),
          ]),
        }));
      },
      // @ts-ignore
      instance: null as WebViewerInstance,
      setInstance: (instance: WebViewerInstance) => {
        set(() => ({ instance }));
        // We also resync the UI
        const state = get();
        if (state.isInReadOnlyMode) {
          state.enableReadOnlyMode();
        } else {
          state.disableReadOnlyMode();
        }
      },
      // @ts-ignore
      dossier: null as Dossier,
      setDossier: (dossier: Dossier) => {
        set(() => ({ dossier }));
      },
      openedInRWMode: null as boolean | null,
      setOpenedInRWMode: (opened: boolean) => {
        set(() => ({ openedInRWMode: opened }));
      },
      roModalOpen: false as boolean,
      setRoModalOpen: (open: boolean) => {
        set(() => ({ roModalOpen: open }));
      },
      isInReadOnlyMode: false as boolean,
      isTemporaryReadOnlyMode: false as boolean,
      lockInfo: null as IDossierLock | null,
      setLockInfo: (lockInfo: IDossierLock) => {
        set(() => ({ lockInfo: lockInfo }));
      },
      enableReadOnlyMode: (temporary: boolean = false) => {
        set(() => ({
          isInReadOnlyMode: true,
          isTemporaryReadOnlyMode: temporary,
        }));
        const state = get();
        if (state.instance) {
          disableEditElementsAndFeatures(get().instance);
        }
      },
      disableReadOnlyMode: () => {
        set(() => ({ isInReadOnlyMode: false }));
        const state = get();
        if (state.instance) {
          enableEditElementsAndFeatures(get().instance);
        }
      },
      hasChangesThatRequireSave: false as boolean,
      setHasChangesThatRequireSave: (hasChanges: boolean) => {
        set(() => ({ hasChangesThatRequireSave: hasChanges }));
      },
      entitiesStatuses: new Map(),
      getEntityStatus: (id: string) => {
        return get().entitiesStatuses.get(id) || AnnotStatus.PENDING;
      },
      // We allow to force the status to be set, to bypass intermediate PENDING state
      // When using keyboard we cannot jump from VALIDATED to REJECTED or vice versa
      // But when we click on the UI we want to force the generation of the new status
      setEntityStatus: (
        id: string,
        targetStatus: AnnotStatus,
        force: boolean = false
      ) => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }
        const prev = get().entitiesStatuses;
        // All statuses might not be set, by default they will be in PENDING
        const currentStatus = prev.get(id) || AnnotStatus.PENDING;
        if (currentStatus === targetStatus) {
          return;
        }

        const next = new Map(prev);

        if (
          force ||
          targetStatus === AnnotStatus.PENDING ||
          currentStatus === AnnotStatus.PENDING
        ) {
          next.set(id, targetStatus);
        } else {
          if (targetStatus === AnnotStatus.VALIDATED) {
            switch (currentStatus) {
              case AnnotStatus.VALIDATED:
                break;
              case AnnotStatus.REJECTED:
                next.set(id, AnnotStatus.PENDING);
                break;
              default:
                break;
            }
          } else if (targetStatus === AnnotStatus.REJECTED) {
            switch (currentStatus) {
              case AnnotStatus.VALIDATED:
                next.set(id, AnnotStatus.PENDING);
                break;
              case AnnotStatus.REJECTED:
                break;
              default:
                break;
            }
          } else {
            throw new Error('Invalid status');
          }
        }
        set(() => ({
          entitiesStatuses: next,
          hasChangesThatRequireSave: true,
        }));
      },
      metaDossierInfoIsLoading: true as boolean,
      entitiesRejectionReasons: new Map<string, string>(),
      setEntityRejectionReason: (entityId: string, reason: string) => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }
        const prev = get().entitiesRejectionReasons;
        const next = new Map(prev);
        next.set(entityId, reason);
        set(() => ({
          entitiesRejectionReasons: next,
          hasChangesThatRequireSave: true,
        }));
      },
      getEntityRejectionReason: (entityId: string) => {
        return get().entitiesRejectionReasons.get(entityId) || '';
      },
      setMetaDossierInfoIsLoading: (isLoading: boolean) => {
        set(() => ({ metaDossierInfoIsLoading: isLoading }));
      },
      metaDossierInfo: undefined as TMetaDossierResponse | undefined,
      setMetaDossierInfo: (metaDossierInfo: TMetaDossierResponse) => {
        // We also update the metaDossierWipElByMetaKey elements
        const prev = get().metaDossierWipElByMetaKey;
        const next = new Map(prev);

        for (const metaKey in metaDossierInfo.metaDossierDataByKey) {
          if (!next.has(metaKey)) {
            // we init the key
            next.set(metaKey, {
              metaKey,
              hasBeenEdited: false,
              label: metaDossierInfo.metaDossierDataByKey[metaKey].label,
              type: metaDossierInfo.metaDossierDataByKey[metaKey].type,
              value: metaDossierInfo.metaDossierDataByKey[metaKey].value,
              remoteValue: metaDossierInfo.metaDossierDataByKey[metaKey].value,
              stringOptions:
                metaDossierInfo.metaDossierDataByKey[metaKey].stringOptions,
            });
          } else {
            // we only update the remote value
            next.set(metaKey, {
              ...next.get(metaKey)!,
              remoteValue: metaDossierInfo.metaDossierDataByKey[metaKey].value,
            });
          }
        }

        for (const metaKey in [...next.keys()]) {
          if (!(metaKey in metaDossierInfo.metaDossierDataByKey)) {
            // we clean previous data
            next.delete(metaKey);
          }
        }

        set(() => ({ metaDossierInfo, metaDossierWipElByMetaKey: next }));
      },
      metaDossierWipElByMetaKey: new Map(),
      metaDossierChanges: {},
      setMetaDossierChanges: (
        metaDossierChanges: Record<string, TSyncValue>
      ) => {
        set({ metaDossierChanges });
      },
      setMetaDossierWipEl: (metaKey: string, value: TSyncValue) => {
        console.log('changeRequested', { metaKey, value });
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }

        const prev = get().metaDossierWipElByMetaKey;
        const next = new Map(prev);

        const info = prev.get(metaKey)!;
        let hasError = false;

        // Errors on other types are not possible at the moment
        // (things are checked input themselves)
        if (info.type === 'email-address') {
          if (value !== null && !z.string().email().safeParse(value).success) {
            hasError = true;
          }
        }

        next.set(metaKey, {
          ...info,
          value,
          hasError,
          hasBeenEdited: true,
        });

        set(() => ({ metaDossierWipElByMetaKey: next }));
      },
      isSavingNewMetaDossierToAbrico: false as boolean,
      saveNewMetaDossierToAbrico: async () => {
        try {
          get().enableReadOnlyMode(true);
          set({ isSavingNewMetaDossierToAbrico: true });

          try {
            enqueueSnackbar(i18n.t('sync.notifications.save.inProgress'), {
              variant: 'info',
            });

            await updateAbricoMetaDossier({
              dossierId: get().dossier.id,
              changes: get().metaDossierChanges,
            });
            await queryClient.invalidateQueries({
              queryKey: [getMetaDossierQueryKey(get().dossier.id)],
            });
            enqueueSnackbar(i18n.t('sync.notifications.save.success'), {
              variant: 'success',
            });
          } catch (e) {
            enqueueSnackbar(i18n.t('sync.notifications.save.failed'), {
              variant: 'error',
            });
          }
        } finally {
          get().disableReadOnlyMode();
          set({ isSavingNewMetaDossierToAbrico: false });
        }
      },
    }),
    {
      name: 'interactions-store',
    }
  )
);

useInteractionsState.subscribe((state, prevState) => {
  if (state.metaDossierWipElByMetaKey !== prevState.metaDossierWipElByMetaKey) {
    const metaDossierChanges: Record<string, TSyncValue> = {};
    for (const [key, info] of state.metaDossierWipElByMetaKey.entries()) {
      if (info.hasError) {
        continue;
      }
      if (info.value !== info.remoteValue) {
        metaDossierChanges[key] = info.value;
      }
    }

    useInteractionsState.getState().setMetaDossierChanges(metaDossierChanges);
  }
});
