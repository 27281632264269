import BlurOn from '@mui/icons-material/BlurOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Close from '@mui/icons-material/Close';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ReactElement } from 'react';

import { AnnotStatus } from 'types/index';

/**
 * Global object to store constants related to annotation status
 * Need to be in a separate file as it contains react component
 * and react would complain about that
 */
export const infoByAnnotStatus: {
  [key in AnnotStatus]: {
    publicCode: string;
    highlightColor: readonly [number, number, number];
    muiColor: 'success' | 'danger' | 'warning';
    icon: {
      missing: ReactElement;
      postProcessed: ReactElement;
      extracted: ReactElement;
    };
  };
} = {
  [AnnotStatus.VALIDATED]: {
    publicCode: 'validation',
    highlightColor: [252, 215, 3],
    muiColor: 'success',
    icon: {
      missing: <VerifiedIcon />,
      postProcessed: <CheckRoundedIcon />,
      extracted: <CheckRoundedIcon />,
    },
  },
  [AnnotStatus.REJECTED]: {
    publicCode: 'rejection',
    highlightColor: [255, 5, 5],
    muiColor: 'danger',
    icon: {
      missing: <CancelIcon />,
      postProcessed: <Close />,
      extracted: <Close />,
    },
  },
  [AnnotStatus.PENDING]: {
    publicCode: 'highlight',
    highlightColor: [40, 200, 217],
    muiColor: 'warning',
    icon: {
      missing: <BlurOn />,
      postProcessed: <BlurOn />,
      extracted: <BlurOn />,
    },
  },
};
