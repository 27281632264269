/* eslint import/named: 0 */ // Buggy for DocumentChange, DocumentData & Unsubscribe :cry:
import Bowser from 'bowser';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import { db } from 'config/firebaseInit';

export const createDossierSession = async (
  dossierId: string,
  companyId: string,
  userEmail: string | null
) => {
  const dossierSessionsRef = collection(db, 'dossierSessions');
  return await addDoc(dossierSessionsRef, {
    dossierId,
    userEmail,
    startTime: serverTimestamp(),
    companyId,
    browser: Bowser.getParser(window.navigator.userAgent).getResult(),
  });
};
