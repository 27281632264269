import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import StoreIcon from '@mui/icons-material/Store';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import Dropdown from '@mui/joy/Dropdown';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import UploadFileModal from 'components/File/UploadFileModal';
import { firebaseAuthProvider } from 'queries/authProvider';
import { useAppState } from 'stores/appStore.ts';
import { closeSidebar } from 'utils/sideBar';

const ListOfTags = () => {
  const { tagId } = useParams();
  const { company, tags } = useAppState();
  return (
    <List
      size="lg"
      sx={{
        gap: 1,
        '--List-nestedInsetStart': '30px',
        '--ListItem-radius': (theme) => theme.vars.radius.sm,
      }}
    >
      {tags.map((tag, index) => (
        <ListItem key={index}>
          <NavLink
            to={`/company/${company?.id}/dossiers/tag/${tag.id}`}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <ListItemButton selected={tag.id === tagId}>
              <AssignmentRoundedIcon color="primary" />
              <ListItemContent>
                <Typography level="title-sm">{tag.name}</Typography>
              </ListItemContent>
            </ListItemButton>
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
};

export default function SideBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user, company, setUser, setCompany, userCompanies } = useAppState();

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        borderRight: '1px solid',
        borderColor: 'divider',
        zIndex: 100,
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton color="primary" size="sm">
          <Avatar size="sm" src="/abrico_letter.svg" />
        </IconButton>
        <Typography level="title-lg">Station Abrico</Typography>
      </Box>

      <UploadFileModal />

      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <Box>
          <ListOfTags />
        </Box>
      </Box>

      <Divider />

      <Stack
        direction={'row'}
        gap={1}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ minWidth: 0 }}
      >
        <Stack gap={1} direction={'row'} alignItems={'center'}>
          <Avatar variant="outlined" size="sm" src={company?.logo?.url} />
          <Typography
            level="title-sm"
            sx={{
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '70px',
            }}
          >
            {user?.email && user?.email?.split('@')[0]}
          </Typography>
        </Stack>
        <Stack gap={1} direction={'row'} alignItems={'center'}>
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
            >
              <StoreIcon />
            </MenuButton>
            <Menu>
              {userCompanies.map((company) => (
                <MenuItem
                  key={company.id}
                  onClick={() => {
                    navigate(`/company/${company.id}/dossiers/tag/all`);
                    setCompany(company);
                  }}
                  selected={company.id === useAppState.getState().company?.id}
                >
                  {company.name}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
          <Tooltip
            title={t('auth.logout')}
            placement="top"
            variant="outlined"
            color="primary"
            sx={{ zIndex: '10000' }}
          >
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              onClick={() => {
                firebaseAuthProvider
                  .signout(() => {
                    setUser(null);
                    setCompany(null);
                  })
                  .then(() => {
                    navigate('/login');
                    console.log('signout success');
                  });
              }}
            >
              <LogoutRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Sheet>
  );
}
