import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import DocEntities from 'components/Entity/DocEntities.tsx';
import useWindowDimensions from 'hooks/useWindowDimensions.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { AnnotStatus } from 'types/index';
import { getEntitiesStatusFromAnnotations } from 'utils/annotations.ts';
import { Dossier } from 'utils/dossier.ts';
import { useKeyBoardNavigation } from 'utils/entities.ts';
import { interactionsState } from 'utils/interactions.ts';

const EntitiesPanel = ({
  dossier,
  panelRef,
  entityGlobalAutoSelectRef,
}: {
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
  dossier: Dossier;
  panelRef: RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.focus();
    }
  }, [panelRef]);

  const { t } = useTranslation('common');
  const instance = useInteractionsState((state) => state.instance);

  const { preparedEntities, goNext, goPrevious, setDocIsExpanded } =
    useKeyBoardNavigation(dossier);

  const [hasRetrievedAnnotations, setHasRetrievedAnnotations] = useState(false);
  useEffect(() => {
    if (instance) {
      const { documentViewer } = instance.Core;

      // FIXME remove this
      // once data has been migrated: https://www.notion.so/abrico/Rework-entities-ID-logic-strip-mentionText-of-ids-and-have-a-non-annonymized-version-will-need-mig-24095f06901740dca808e17edbe6ee86?pm=c
      documentViewer.addEventListener('annotationsLoaded', async () => {
        if (!hasRetrievedAnnotations) {
          useInteractionsState.getState().mergeData({
            entitiesStatuses: getEntitiesStatusFromAnnotations(instance),
          });
          setHasRetrievedAnnotations(true);
        }
      });
    }
  }, [hasRetrievedAnnotations, instance]);

  const { height } = useWindowDimensions();

  const focusedEntityId = useUiState(
    useShallow((state) => state.focusedEntityId)
  );

  useEffect(() => {
    if (instance && focusedEntityId) {
      const { annotationManager } = instance.Core;
      const annot = annotationManager.getAnnotationById(focusedEntityId);
      // On chrome we cannot scroll 2 panels at the same time
      // Since we are focusing entities from the DPF too, we shouldn't always jump to it
      if (annot && interactionsState.mode === 'FROM_PANEL_TO_PDF')
        annotationManager.jumpToAnnotation(annot, {
          isSmoothScroll: true,
        });
    }
  }, [focusedEntityId, instance]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent, currentId: string) => {
      if (
        !(
          e.key === 'ArrowDown' ||
          e.key === 'ArrowUp' ||
          e.key === 'Enter' ||
          e.key === 'ArrowLeft' ||
          e.key === 'ArrowRight'
        )
      ) {
        return;
      }
      e.preventDefault();
      if (e.key === 'ArrowDown') goNext();
      else if (e.key === 'ArrowUp') goPrevious();
      else if (e.key === 'Enter' || e.key === 'ArrowRight') {
        useInteractionsState
          .getState()
          .setEntityStatus(currentId, AnnotStatus.VALIDATED);
      } else if (e.key === 'ArrowLeft') {
        useInteractionsState
          .getState()
          .setEntityStatus(currentId, AnnotStatus.REJECTED);
      }
    },
    [goNext, goPrevious]
  );

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e, focusedEntityId)}
      ref={panelRef}
      style={{ outline: 'none' }}
    >
      {preparedEntities.map(({ entitiesForDoc, docType }) => (
        <Stack
          key={docType}
          direction="column"
          sx={{ width: '100%', marginTop: 2 }}
        >
          <Typography
            level="title-lg"
            style={{ textAlign: 'center' }}
            sx={{ fontWeight: 'bold' }}
            textTransform={'uppercase'}
          >
            {t(`docTypes.${docType}.name`)}
          </Typography>
          {entitiesForDoc.map(({ preparedSection, docKey, isLastDoc }) => (
            <div
              key={docKey}
              style={
                // Hacky way to add some space at the bottom to make sure
                // the last section can be displayed entirely from the top (looks nicier)
                isLastDoc ? { minHeight: '100vh' } : {}
              }
            >
              <DocEntities
                {...{
                  docType,
                  docKey,
                  preparedSection,
                  setDocIsExpanded,
                  scrollOffset: height * 0.2,
                  entityGlobalAutoSelectRef,
                }}
                docExtraLabel={dossier.docExtraLabelByDocKey[docKey]}
              />
            </div>
          ))}
        </Stack>
      ))}
    </div>
  );
};

export default EntitiesPanel;
