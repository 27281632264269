import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SignatureValidationInfo from 'components/UI/SignatureValidationInfo/SignatureValidationInfo';
import WebViewerWrapper from 'components/WebViewer/WebViewerWrapper';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { VerifyAllAndPrint } from 'utils/checkSignature';
import { reorderDocumentPages } from 'utils/reorderDocumentPages.ts';
import { logDurationInFs } from 'utils/timings.ts';

const TRUSTED_CERTIFICATE_URL =
  'https://storage.googleapis.com/list-of-trusted-certificates/Signature_export_CertExchange%20_branch_io(1).fdf';

const YourCustomPdfViewer = () => {
  const { company } = useAppState();
  const instance = useInteractionsState((state) => state.instance);

  const [verificationResults, setVerificationResults] = useState([]);
  const signatureModalOpen = useUiState((state) => state.signatureModalOpen);
  const setSignatureModalOpen = useUiState(
    (state) => state.setSignatureModalOpen
  );

  const [initHasRun, setInitHasRun] = useState(false);

  const NbeSignature = ({ numberE }: { numberE: number }) => {
    const { t } = useTranslation('apryse');
    return <p> {t('action.signatureFields', { count: numberE })} </p>;
  };

  const initView = useCallback(async () => {
    if (initHasRun) return;
    setInitHasRun(true);
    const dossier = useInteractionsState.getState().dossier;

    const { Core, UI } = instance;
    const { documentViewer, PDFNet } = Core;
    const { openElements, closeElements, loadDocument } = UI;

    // So initView is going to run only once by design with the "initHasRun" state
    // But we also want to run the content of "handleDocumentJustFullyLoaded" only once
    // after the annotations have been loaded (hence it being attached to the event handler bellow).
    // We cannot rely on initHasRun here as it will always be false inside handleDocumentJustFullyLoaded
    // Due to how react manages state cache. So We use shouldAutoDestroy to handle this
    // deterministically, and by removing the event handler.
    let shouldAutoDestroy = false;
    const handleDocumentJustFullyLoaded = async () => {
      // We auto destroy the event listener :nerd_face:
      if (shouldAutoDestroy) {
        documentViewer.removeEventListener(
          'annotationsLoaded',

          handleDocumentJustFullyLoaded
        );
        return;
      }
      // We want to run this only once.
      shouldAutoDestroy = true;
      await dossier.refreshUI();

      if (dossier.nbDocuments > 1) {
        openElements(['outlinesPanel']);
      } else {
        closeElements(['outlinesPanel']);
      }

      // We have made the critical changes to the document, we can move on
      useUiState.getState().setViewerIsReady(true);
      logDurationInFs({ durationMs: performance.now(), name: 'Viewer Ready' });

      await VerifyAllAndPrint(
        await instance.Core.documentViewer.getDocument().getPDFDoc(),
        PDFNet
      )
        .then((res: any) => {
          setVerificationResults(res);
        })
        .catch((err) => console.error('error in VerifyAllAndPrint', err));
    };

    // important to wrap all actions on the document in the documentLoaded event
    documentViewer.addEventListener(
      'annotationsLoaded',
      handleDocumentJustFullyLoaded
    );

    fetch(await dossier.getDownloadUrl()).then(async (pdfResponse) => {
      const rawPdfBytes = new Uint8Array(await pdfResponse.arrayBuffer());

      const rawPdfDocument = await PDFNet.PDFDoc.createFromBuffer(
        Uint8Array.from(rawPdfBytes)
      );
      let reorderedPdf = rawPdfDocument;
      if (!dossier.disableAutoReorder) {
        reorderedPdf = await reorderDocumentPages(
          rawPdfDocument,
          dossier.pageNumbersMappingForReordering!,
          instance
        );
      }

      loadDocument(reorderedPdf, { filename: dossier.fileName });

      const response = await fetch(TRUSTED_CERTIFICATE_URL);
      const trustedList = await response.arrayBuffer();
      instance.UI.VerificationOptions.loadTrustList(trustedList);
    });
  }, [initHasRun, instance]);

  useEffect(() => {
    if (instance && !initHasRun) initView();
  }, [instance, initHasRun, initView]);

  if (!company?.id) return <p> Please sign in with your professional email </p>;
  return (
    <div
      className="webWivewerWrapper"
      style={{ height: '100vh', width: '100%' }}
    >
      <WebViewerWrapper />

      {instance && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={signatureModalOpen}
          onClose={() => setSignatureModalOpen(false)}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              border: 'none',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            {verificationResults && verificationResults.length > 0 && (
              <NbeSignature numberE={verificationResults.length} />
            )}

            {verificationResults && verificationResults.length > 0 && (
              <Stack spacing={1}>
                {verificationResults.map(
                  (verificationResult, index: number) => {
                    return (
                      <div key={index}>
                        <SignatureValidationInfo
                          key={index}
                          verificationResult={verificationResult}
                        />
                      </div>
                    );
                  }
                )}
              </Stack>
            )}

            {verificationResults.length === 0 && (
              <div>
                <h2>Aucune signature électronique trouvée</h2>
              </div>
            )}
          </Sheet>
        </Modal>
      )}
    </div>
  );
};

export default YourCustomPdfViewer;
