import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
} from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { MutableRefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DocSectionEntities from 'components/Entity/DocSectionEntities.tsx';
import { useAppState } from 'stores/appStore.ts';
import { IDocType, IPreparedDocSection } from 'types/index';

type Props = {
  preparedSection: IPreparedDocSection[];
  docType: IDocType;
  docKey: string;
  scrollOffset: number;
  setDocIsExpanded: (docKey: string, isExpanded: boolean) => void;
  docExtraLabel: string;
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
};

const DocEntities = ({
  preparedSection,
  docType,
  docKey,
  setDocIsExpanded,
  scrollOffset,
  docExtraLabel,
  entityGlobalAutoSelectRef,
}: Props) => {
  const { t } = useTranslation('common');
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const { company } = useAppState();

  useEffect(() => {
    setDocIsExpanded(docKey, expanded);
  }, [docKey, expanded, setDocIsExpanded]);

  return (
    <AccordionGroup
      variant="plain"
      transition="0.3s"
      sx={{
        margin: 2,
        padding: 1,
        backgroundColor: '#22252b',
        borderRadius: 8,
      }}
    >
      <Accordion expanded={expanded} onChange={(_, a) => setExpanded(a)}>
        <AccordionSummary
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#22252b',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        >
          <Typography level="title-md" sx={{ fontWeight: 'bold' }}>
            {`${t(`docTypes.${docType}.name`)} ${docExtraLabel}`}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          style={{
            // We set the visibility to 0 to make checkElementIsVisible work properly
            opacity: expanded ? 'unset' : 0,
          }}
        >
          <Stack>
            {preparedSection
              .filter(
                ({ groups }) =>
                  company?.featureFlags.handleMissingFields ||
                  groups.filter((group) => group.entities.length > 0).length > 0
              )
              .map(({ sectionName, groups, missingEntities }) => (
                <DocSectionEntities
                  key={sectionName}
                  {...{
                    sectionName,
                    groups,
                    missingEntities,
                    docType,
                    docKey,
                    scrollOffset,
                    entityGlobalAutoSelectRef,
                  }}
                />
              ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
};

export default DocEntities;
