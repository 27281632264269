import './instrument';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, StyledEngineProvider } from '@mui/joy/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { theme } from './theme';

import AuthLayout from 'components/UI/AuthLayout';
import FilesTable from 'components/UI/FilesTable';
import FullScreenLoader from 'components/UI/FullScreenLoader.tsx';
import Login from 'components/UI/Login';
import PasswordReset from 'components/UI/PasswordReset';
import SideBarLayout from 'components/UI/SideBarLayout';
import Station from 'components/UI/Station';
import { queryClient } from 'queries';
import { loginAction, passwordResetAction } from 'queries/authAction';
import ErrorPage from 'routes/errorPage';
import ProtectedRoot from 'routes/protectedRoot';
import { routesPath } from 'utils/constants';
import 'dayjs/locale/fr'; // load on demand

// eslint-disable-next-line import/no-unresolved
import '@mdxeditor/editor/style.css';
import './components/Editor/editor.scss';

import './i18n';
dayjs.locale('fr'); // use Spanish locale globally

// Hack to init the local storage for apryze trial period reset
localStorage.setItem('init_timestamp', Date.now().toString());

const router = createBrowserRouter([
  {
    path: routesPath.root,
    element: <ProtectedRoot />,
    children: [
      {
        element: <SideBarLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: routesPath.dossiersByTag,
            element: <FilesTable />,
          },
        ],
      },
      {
        path: routesPath.dossier,
        element: <Station />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routesPath.login,
        element: <Login />,
        action: loginAction,
      },
      {
        path: routesPath.passwordReset,
        element: <PasswordReset />,
        action: passwordResetAction,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Suspense fallback={<FullScreenLoader />}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <CssVarsProvider
            theme={theme}
            defaultMode="dark"
            modeStorageKey="joy-mode-scheme-dark"
          >
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <RouterProvider router={router} />
            </SnackbarProvider>
          </CssVarsProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>
);
