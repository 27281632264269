import {
  BoldItalicUnderlineToggles,
  frontmatterPlugin,
  headingsPlugin,
  listsPlugin,
  MDXEditor,
  quotePlugin,
  toolbarPlugin,
  BlockTypeSelect,
  UndoRedo,
  Separator,
  ListsToggle,
  markdownShortcutPlugin,
  // eslint-disable-next-line import/named
  MDXEditorMethods,
} from '@mdxeditor/editor';
import { useEffect, useRef } from 'react';

export function MarkdownEditor({
  readOnly,
  value,
  onChange = () => {},
  placeholder = 'Write something...',
}: {
  readOnly: boolean;
  value: string;
  onChange?: (markdown: string) => void;
  placeholder?: string;
}) {
  const ref = useRef<MDXEditorMethods>(null);

  useEffect(() => {
    if (ref.current?.getMarkdown() !== value) {
      ref.current?.setMarkdown(value);
    }
  }, [value]);

  return (
    <MDXEditor
      ref={ref}
      className="dark-theme dark-editor"
      markdown={value}
      readOnly={readOnly}
      onChange={onChange}
      placeholder={placeholder}
      plugins={[
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <UndoRedo />
              <Separator />
              <BoldItalicUnderlineToggles />
              <Separator />
              <BlockTypeSelect />
              <ListsToggle />
            </>
          ),
        }),
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        frontmatterPlugin(),
        markdownShortcutPlugin(),
      ]}
    />
  );
}
