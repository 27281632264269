/**
 * Retrieves the relevant information from the given object, containing date
 * information that originates from epoch time, and returns a string containing
 * the date and time information in a human readable string
 *
 * @param {object} date The date object that is returned from PDFNet
 * @returns {string} Human readable formatted date and time
 * @ignore
 */
import { FieldValue } from '@firebase/firestore';

type PdfTronDate = {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
};

export const formatPDFNetDate = (
  date: PdfTronDate,
  currentLanguage: string
) => {
  const { year, month, day, hour, minute, second } = date;
  const d = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

  return d.toLocaleDateString(currentLanguage.replace('_', '-'), {
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });
};

/**
 * Converts an epoch time input to date in string
 *
 * @param {number} epochTime The epoch time to be converted
 * @returns {string} The converted epoch time
 * @ignore
 */
export const formatDate = (epochTime: number, currentLanguage: string) => {
  const date = new Date(0);
  // Values greater than 59 are converted into their parent values
  // (i.e. seconds -> minutes -> hours -> day etc.)
  date.setUTCSeconds(epochTime);

  return date.toLocaleDateString(currentLanguage.replace('_', '-'), {
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });
};

export const formatTimeStamp = (value?: FieldValue | null): string => {
  if (!value) return '';
  // @ts-ignore
  const d = new Date(value?.seconds * 1000);
  const date = d.toLocaleDateString('fr');
  const time = d.toLocaleTimeString('fr');
  return `${date} ${time}`;
};
