import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { renderPermissionStatus } from 'components/SignaturePanel/WidgetInfo';
import SignatureBadgeIcon from 'components/UI/SignatureBadgeIcon';
import { switchButton } from 'components/UI/switchButton';
import { useInteractionsState } from 'stores/interactionsStore.ts';

const SignatureValidationInfo = ({
  verificationResult,
}: {
  verificationResult: any;
}) => {
  const [translate] = useTranslation('apryse');
  const [checked, setChecked] = React.useState(false);
  const instance = useInteractionsState((state) => state.instance);
  const { PDFNet } = instance.Core;

  const {
    badgeIcon,
    verificationStatus,
    permissionStatus,
    isCertification,
    documentPermission,
    trustVerificationResultString,
    timeOfTrustVerificationEnum,
    trustVerificationTime,
    digestAlgorithm,
    digestStatus,
    documentStatus,
    //trustStatus,
    signerName,
    reason,
    signer,
    formatedSignTime,
  } = verificationResult;
  const {
    DigestAlgorithm,
    DigitalSignatureField,
    VerificationOptions,
    VerificationResult,
  } = PDFNet;
  const {
    ModificationPermissionsStatus,
    //TrustStatus,
    DigestStatus,
    DocumentStatus,
  } = VerificationResult;
  const { TimeMode } = VerificationOptions;

  const renderHeader = () => {
    return (
      <Card>
        <Stack direction="row" justifyContent="space-between">
          <SignatureBadgeIcon badgeIcon={badgeIcon} />
          {switchButton({ checked, setChecked })}
        </Stack>
      </Card>
    );
  };

  /**
   * Returns a message in a <p> tag corresponding to the enum value of
   * documentPermission, which originates from the invocation of
   * PDFNet.DigitalSignatureField.getDocumentPermissions
   */
  const renderDocumentPermission = () => {
    if (!documentPermission) {
      return;
    }

    let content = '';
    const editor = isCertification
      ? translate('digitalSignatureVerification.certifier')
      : translate('digitalSignatureVerification.signer');

    switch (documentPermission) {
      case DigitalSignatureField.DocumentPermissions.e_no_changes_allowed:
        content += translate(
          'digitalSignatureModal.documentPermission.noChangesAllowed',
          { editor }
        );
        break;
      case DigitalSignatureField.DocumentPermissions
        .e_formfilling_signing_allowed:
        content += translate(
          'digitalSignatureModal.documentPermission.formfillingSigningAllowed',
          { editor }
        );
        break;
      case DigitalSignatureField.DocumentPermissions
        .e_annotating_formfilling_signing_allowed:
        content += translate(
          'digitalSignatureModal.documentPermission.annotatingFormfillingSigningAllowed',
          { editor }
        );
        break;
      case DigitalSignatureField.DocumentPermissions.e_unrestricted:
        content += translate(
          'digitalSignatureModal.documentPermission.unrestricted',
          { editor }
        );
        break;
    }

    return <Typography level="body-sm">{content}</Typography>;
  };

  /**
   * Returns a message in a <p> tag corresponding to the signature's trust
   * verification result.
   *
   * If trustVerificationResultString is a falsy value (i.e. undefined, null or
   * empty string), originating from the invocation of
   * PDFNet.TrustVerificationResult.getResultString, then a message indicating
   * no trust verification result is rendered.
   *
   * If a trust verification result is available, then the based on
   * timeOfTrustVerificationEnum, which originates from the invocation of
   * PDFNet.TrustVerificationResult.getTimeOfTrustVerificationEnum, an
   * appropriate message is rendered
   */
  const renderTrustVerification = () => {
    if (!trustVerificationResultString) {
      return <p>{translate('digitalSignatureModal.trustVerification.none')}</p>;
    }

    let content = '';
    switch (timeOfTrustVerificationEnum) {
      case TimeMode.e_current:
        content += translate(
          'digitalSignatureModal.trustVerification.current',
          { trustVerificationTime }
        );
        break;
      case TimeMode.e_signing:
        content += translate(
          'digitalSignatureModal.trustVerification.signing',
          { trustVerificationTime }
        );
        break;
      case TimeMode.e_timestamp:
        content += translate(
          'digitalSignatureModal.trustVerification.timestamp',
          { trustVerificationTime }
        );
        break;
    }

    return <Typography level="body-sm">{content}</Typography>;
  };

  const renderSigningTime = () => {
    const content = translate('digitalSignatureModal.signingTime', {
      formatedSignTime,
    });
    return <Typography level="body-sm">{content}</Typography>;
  };

  const renderIdentity = () => {
    let identity = signerName || reason || signer;
    let content = '';
    if (identity.includes('Signed by')) {
      identity = identity.split('Signed by ')[1];
      content += translate('digitalSignatureModal.signerIdentity.preamble', {
        identity,
      });
    } else {
      content += translate('digitalSignatureModal.signerIdentity.reason', {
        reason,
      });
    }
    return <Typography level="body-sm">{content}</Typography>;
  };

  /**
   * Returns a message in a <p> tag corresponding to the signature's digest
   * algorithm, which originates from the invocation of
   * PDFNet.verificationResult.getDigestAlgorithm
   */
  const renderDigestAlgorithm = () => {
    let content = translate('digitalSignatureModal.digestAlgorithm.preamble');

    switch (digestAlgorithm) {
      case DigestAlgorithm.Type.e_SHA1:
        content += 'SHA1.';
        break;
      case DigestAlgorithm.Type.e_SHA256:
        content += 'SHA256.';
        break;
      case DigestAlgorithm.Type.e_SHA384:
        content += 'SHA384.';
        break;
      case DigestAlgorithm.Type.e_SHA512:
        content += 'SHA512.';
        break;
      case DigestAlgorithm.Type.e_RIPEMD160:
        content += 'RIPEMD160.';
        break;
      case DigestAlgorithm.Type.e_unknown_digest_algorithm:
        content = translate('digitalSignatureModal.digestAlgorithm.unknown');
        break;
    }

    return <Typography level="body-sm">{content}</Typography>;
  };

  const renderDocumentStatus = () => {
    let content;

    switch (documentStatus) {
      case DocumentStatus.e_no_error:
        content = translate(
          'digitalSignatureVerification.documentStatus.noError'
        );
        break;
      case DocumentStatus.e_corrupt_file:
        content = translate(
          'digitalSignatureVerification.documentStatus.corruptFile'
        );
        break;
      case DocumentStatus.e_unsigned:
        content = translate(
          'digitalSignatureVerification.documentStatus.unsigned'
        );
        break;
      case DocumentStatus.e_bad_byteranges:
        content = translate(
          'digitalSignatureVerification.documentStatus.badByteRanges'
        );
        break;
      case DocumentStatus.e_corrupt_cryptographic_contents:
        content = translate(
          'digitalSignatureVerification.documentStatus.corruptCryptographicContents'
        );
        break;
    }

    return <Typography level="body-sm">{content}</Typography>;
  };

  const renderDigestStatus = () => {
    let content;

    switch (digestStatus) {
      case DigestStatus.e_digest_invalid:
        content = translate(
          'digitalSignatureVerification.digestStatus.digestInvalid'
        );
        break;
      case DigestStatus.e_digest_verified:
        content = translate(
          'digitalSignatureVerification.digestStatus.digestVerified'
        );
        break;
      case DigestStatus.e_digest_verification_disabled:
        content = translate(
          'digitalSignatureVerification.digestStatus.digestVerificationDisabled'
        );
        break;
      case DigestStatus.e_weak_digest_algorithm_but_digest_verifiable:
        content = translate(
          'digitalSignatureVerification.digestStatus.weakDigestAlgorithmButDigestVerifiable'
        );
        break;
      case DigestStatus.e_no_digest_status:
        content = translate(
          'digitalSignatureVerification.digestStatus.noDigestStatus'
        );
        break;
      case DigestStatus.e_unsupported_encoding:
        content = translate(
          'digitalSignatureVerification.digestStatus.unsupportedEncoding'
        );
        break;
    }

    return <Typography level="body-sm">{content}</Typography>;
  };

  /*const renderTrustStatus = () => {
    const verificationType = isCertification
      ? translate('digitalSignatureVerification.certifier')
      : translate('digitalSignatureVerification.signer');
    let content;

    switch (trustStatus) {
      case TrustStatus.e_trust_verified:
        content = translate(
          'digitalSignatureVerification.trustStatus.trustVerified',
          { verificationType },
        );
        break;
      case TrustStatus.e_untrusted:
        content = translate(
          'digitalSignatureVerification.trustStatus.untrusted'
        );
        break;
      case TrustStatus.e_trust_verification_disabled:
        content = translate(
          'digitalSignatureVerification.trustStatus.trustVerificationDisabled'
        );
        break;
      case TrustStatus.e_no_trust_status:
        content = translate(
          'digitalSignatureVerification.trustStatus.noTrustStatus'
        );
        break;
    }

    return <Typography level="body-sm">{content}</Typography>;
  };*/

  const renderDocumentIntegrityHeader = () => (
    <Typography level="title-md">
      {translate('digitalSignatureModal.header.documentIntegrity')}
    </Typography>
  );

  const renderIdentitiesTrustHeader = () => (
    <Typography level="title-md">
      {translate('digitalSignatureModal.header.identitiesTrust')}
    </Typography>
  );

  const renderGeneralErrorsHeader = () => (
    <Typography level="title-md">
      {translate('digitalSignatureModal.header.generalErrors')}
    </Typography>
  );

  const renderDigestStatusHeader = () => (
    <Typography level="title-md">
      {translate('digitalSignatureModal.header.digestStatus')}
    </Typography>
  );

  const renderModalBody = () => {
    if (typeof verificationStatus === 'undefined') {
      return (
        <div className="center">
          <p> spinner </p>
        </div>
      );
    }
    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Box>
          {renderDocumentIntegrityHeader()}
          <List
            marker="disc"
            size="sm"
            sx={{ '--ListItem-paddingY': 0, '--ListItem-minHeight': '1px' }}
          >
            <ListItem>
              {renderPermissionStatus({
                isCertification,
                ModificationPermissionsStatus,
                permissionStatus,
                translate,
              })}
            </ListItem>
            <ListItem>{renderDocumentPermission()}</ListItem>
          </List>
        </Box>

        <Box>
          {renderIdentitiesTrustHeader()}
          <List
            marker="disc"
            size="sm"
            sx={{ '--ListItem-paddingY': 0, '--ListItem-minHeight': '1px' }}
          >
            {/*<ListItem >
                {renderTrustStatus()}
                </ListItem>
              */}

            {formatedSignTime && <ListItem>{renderSigningTime()}</ListItem>}

            <ListItem>{renderTrustVerification()}</ListItem>

            {(signerName || reason) && <ListItem>{renderIdentity()}</ListItem>}
          </List>
        </Box>
        <Box>
          {renderGeneralErrorsHeader()}
          {renderDocumentStatus()}
        </Box>

        <Box>
          {renderDigestStatusHeader()}
          <List
            marker="disc"
            size="sm"
            sx={{ '--ListItem-paddingY': 0, '--ListItem-minHeight': '1px' }}
          >
            <ListItem>{renderDigestStatus()}</ListItem>
            <ListItem>{renderDigestAlgorithm()}</ListItem>
          </List>
        </Box>
      </Stack>
    );
  };

  return (
    <Stack spacing={1}>
      {renderHeader()}
      {checked && renderModalBody()}
    </Stack>
  );
};

export default SignatureValidationInfo;
