import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';

export default function FullScreenLoader({ message }: { message?: string }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(20,20,20,0.25)',
        zIndex: 10000,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <CircularProgress />
        {message && (
          <Typography
            sx={{ mt: 4, padding: 2, borderRadius: 8 }}
            variant={'soft'}
            level={'title-lg'}
            whiteSpace={'pre-wrap'}
            textAlign={'center'}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
