import { enqueueSnackbar } from 'notistack';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { generateReviewFeedback } from 'cloudFunctions/functions.ts';
import i18n from 'i18n.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { TReviewInfo } from 'types/index';

interface UiState {
  focusedEntityId: string;
  autoFocus: boolean;
  setFocusedEntityId: (id: string, autoFocus?: boolean) => void;
  viewerIsReady: boolean;
  setViewerIsReady: (isReady: boolean) => void;
  signatureModalOpen: boolean;
  setSignatureModalOpen: (isOpen: boolean) => void;
  metaFieldBeingEdited: string | null;
  setMetaFieldBeingEdited: (field: string | null) => void;
  reviewFeedbackMarkdown: string;
  setReviewMarkdown: (markdown: string) => void;
  generateAiReviewFeedback: (reviewInfo: TReviewInfo) => Promise<void>;
  generateAiReviewFeedbackLoading: boolean;
}

export const useUiState = create<UiState>()(
  devtools(
    (set) => ({
      viewerIsReady: false as boolean,
      setViewerIsReady: (isReady: boolean) => {
        set(() => ({ viewerIsReady: isReady }));
      },
      signatureModalOpen: false as boolean,
      setSignatureModalOpen: (isOpen: boolean) => {
        // we disable this feature for now
        isOpen = false;
        set(() => ({ signatureModalOpen: isOpen }));
      },
      autoFocus: false as boolean,
      focusedEntityId: '',
      setFocusedEntityId: (id: string, autoFocus: boolean = true) => {
        set(() => ({ focusedEntityId: id, autoFocus }));
      },
      metaFieldBeingEdited: '' as string | null,
      setMetaFieldBeingEdited: (field: string | null) => {
        set(() => ({ metaFieldBeingEdited: field }));
      },
      reviewFeedbackMarkdown: '' as string,
      setReviewMarkdown: (markdown: string) => {
        set(() => ({ reviewFeedbackMarkdown: markdown }));
      },
      generateAiReviewFeedback: async (reviewInfo: TReviewInfo) => {
        set({ generateAiReviewFeedbackLoading: true });
        try {
          const { data } = await generateReviewFeedback({
            dossierId: useInteractionsState.getState().dossier!.id,
            rejections: reviewInfo.rejectedEntities.map((el) => ({
              label: el.label,
              rejectionReason: el.rejectionReason,
              isMissing: el.kind === 'missing',
            })),
          });
          if (!data.succeeded) {
            throw new Error('Failed to generate feedback');
          }

          const { reviewFeedbackMarkdown } = data;
          set({ reviewFeedbackMarkdown });
          enqueueSnackbar(i18n.t('review.ai.notification.success'), {
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          enqueueSnackbar(i18n.t('review.ai.notification.error'), {
            variant: 'error',
          });
        } finally {
          set({ generateAiReviewFeedbackLoading: false });
        }
      },
      generateAiReviewFeedbackLoading: false as boolean,
    }),
    {
      name: 'ui-store',
    }
  )
);
