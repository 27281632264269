import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Sheet from '@mui/joy/Sheet';
import * as React from 'react';

type Props = {
  placeholder: string;
  handleChange: (searched: string) => void;
  searchBarLabel?: string;
};

export default function SearchBar(props: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (inputRef && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: 'flex', sm: 'none', width: '100%' },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          variant="outlined"
          ref={inputRef}
          size="sm"
          placeholder={props.placeholder}
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
          onChange={(event) => props.handleChange(event.target.value)}
        />
      </Sheet>

      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
          width: '100%',
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          {props.searchBarLabel && (
            <FormLabel>{props.searchBarLabel}</FormLabel>
          )}
          <Input
            variant="outlined"
            ref={inputRef}
            size="sm"
            placeholder={props.placeholder}
            startDecorator={<SearchIcon />}
            onChange={(event) => props.handleChange(event.target.value)}
          />
        </FormControl>
      </Box>
    </React.Fragment>
  );
}
