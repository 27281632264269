import { useEffect } from 'react';

import { logDurationInFs } from 'utils/timings.ts';

// Make sure we log only once we TrackTiming component (useState is not reactive enough)
const tracker = new Set();

export function TrackTiming({ name }: { name: string }) {
  useEffect(() => {
    if (!tracker.has(name)) {
      tracker.add(name);
      logDurationInFs({ durationMs: performance.now(), name });
    }
  }, [name]);
  return <></>;
}
